<template>
  <div class="">

    <admin @admin_allow='admin_allow' v-if="preview_mode" />

    <div v-if="allowed">

      <div class="" v-if="show === 'yes'">

        <div class="" v-bind:style="{ backgroundColor: color_course }" id="lesson_nav_wrapper">
          <div class="relative mode-center lesson_nav_wrap flex items-center">

            <div class="flex flex-wrap flex-none w-full pt-1 mxmd:pb-4 mxmd:pt-2">
              <div class="mr-2 flex-none mxxsm:w-full mnmd:-ml-3 relative">

                  <button @click.prevent="lesson_sidebar()" class="text-subhdr-3focus:outline-none block lesson_btn rounded-md px-1 py-1 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
                    
                    <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
                    <!-- <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg> -->
                  </button>

                  <div class="tooltip_wrap z-50" id="sidebar_hint" v-if="show_sidebar_hint">
                    <div class="arrow-up"></div>
                    <div class="arrow-left"></div>
                    <div class="rounded bg-black text-white py-2 px-3 select-none">
                      Inhoudsopgave
                    </div>
                  </div>
                  <!--  
                  -->

              </div>
              <div class="flex-none mxmd:flex-grow mr-2">

                  <button @click.prevent="navigate_lesson('-1')" v-bind:class="[show_nav_prev ? '' : 'invisible']"
                  class="text-subhdr-3 block lesson_btn mt-1 rounded-md px-2 pt-1 mxmd:pt-2 pb-2 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
                    <div class="inline-block align-middle mr-2">   
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>                
                    </div>
                    <div class="inline-block align-middle font-bold">                   
                      vorige
                    </div>
                  </button>

              </div>
              <div class=" flex-grow mxmd:order-last mxmd:w-full mxmd:pt-2 mxmd:pl-2 mr-2 flex items-center">
                <progress_bar progress_text="voortgang" :percentage="percentage" />
              </div>
              <div class="text-right flex-none mxmd:flex-grow">

                <div class="inline-block relative" v-bind:style="opac_nav_next">

                  <button @click.prevent="navigate_lesson('+1')" v-bind:class="[show_nav_next ? '' : 'invisible']" 
                  class="text-subhdr-3 block lesson_btn mt-1 rounded-md px-2 pt-1 mxmd:pt-2 pb-2 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
                    <div class="inline-block align-middle font-bold mr-2">                   
                      volgende
                    </div>
                    <div class="inline-block align-middle">                    
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </div>
                  </button>

                  <div class="pos-g" v-show="nav_next_disabled"></div>
                </div>
                

              </div>
            </div>
            <!--  
            -->
          </div>
        </div>

        <div class="relative" v-bind:style="{ backgroundColor: color_course }">

          <div class="" id="lesson_intro">
            <lesson_home :lesson_homeObj="lesson_homeObj" />
          </div>

          <div class="hidden" id="lesson_end">
            <lesson_end :lesson_endObj="lesson_endObj" />
          </div>

          <div class="relative hidden" id="lesson_content">

            <div class="relative">
              <lesson_block  
                v-for="(block, index) in blocks"
                :key="index + 1" 
                :id="index + 1"
                :type="block.type" 
                :blockObj="block.blockObj" 
              />
            </div>

            <!-- 
            <div class="pos-g text-center pointer-events-none" style="opacity:0.2;">
              <img src="@/assets/img/temp/blocks/slider.jpg" class="inline-block" style="width:1080px;" />
            </div>
            -->

          </div>
            
          <div class="pos-g hidden" v-bind:style="{ backgroundColor: color_course }" id="lesson_curtain"></div>

        </div>

      </div>

      <page_404 v-if="show === '404'" />

      <sidebar_lesson :sidebar="sidebar" :sidebar_active_item="sidebar_active_item" :sidebar_active="sidebar_active" @sidebar-x="lesson_sidebar" @lesson-nav="sidebar_nav" v-click-outside="sidebar_blur" />


    </div>

    <modal_lesson />


  </div>
</template>



<script>

import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import admin from '@/components/account/admin'

import page_404 from '@/components/page_404.vue'
import progress_bar from '@/components/misc/progress_bar.vue'
import lesson_home from '@/components/lesson_home.vue'
import lesson_end from '@/components/lesson_end.vue'
import lesson_block from '@/components/lesson_block.vue'
import sidebar_lesson from '@/components/ui/sidebar_lesson.vue'
import modal_lesson from '@/components/ui/modal_lesson.vue'

var lv = {}
lv.thisVue = null

lv.slug = ''
lv.dataTaskString = ''

lv.nav_total = 0
lv.nav_cur = 0

lv.sidebar_busy = false

export default {
  name: "Lesson",
  components: {
    page_404,
    progress_bar,
    lesson_home,
    lesson_end,
    lesson_block,
    sidebar_lesson,
    modal_lesson,
    admin
  },
  data() {
    return {
      id: null,
      selectedIndexItem: null,
      color_course: '#ddd',
      show: "",
      blocks: [],
      show_nav_prev: false,
      show_nav_next: true,
      nav_next_disabled: false,
      opac_nav_next: '',
      lesson_homeObj: {},
      lesson_endObj: {},
      percentage: 0,
      sidebar: {
        color: null,
        nav: null
      },
      sidebar_active_item: 1,
      sidebar_active: false,
      preview_mode: false,
      allowed: true,
      show_sidebar_hint: true
    }
  },
  mounted() {

    lv.thisVue = this
    lv.thisVue.id = 0
    lv.slug = lv.thisVue.$route.params.lesson

    this.checkPreviewMode()

    this.sidebar_active = false
    this.sidebar_active_item = 1

    if(localStorage.getItem('sidebar_hint')){
        this.show_sidebar_hint = false
    }

    EventBus.$on('lesson_block_done', (e) => {
      if(e.lesson == lv.thisVue.$route.params.lesson){
        this.sidebar.nav[e.index].usable = true
        this.sidebar.nav[e.index + 1].usable = true
      }
    })
    EventBus.$on('percentage_change_lesson', (e) => {
      if(lv.thisVue.id == e.lesson_id){
        this.percentage = e.percentages.lesson
     }
    })

    lv.dataTask = "lesson_fetch";
    lv.dataEvent = "data_lesson_detail";
    api.call({
      task: lv.dataTask,
      slug: lv.slug,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {
      
      lv.nav_total = 0
      lv.nav_cur = 0
      lv.sidebar_busy = false
      lv.thisVue.sidebar_active_item = 1

      if (d.result != "ok") {
        control_main.console_log("----- data load fail -----")
      }
      if (d.result == "ok") {
        //control_main.console_log("----- data load success -----")

        if (d.data.respond == "404") {
          control_main.console_log("dont have that one")
          lv.thisVue.show = "404"
          return
        }

        lv.thisVue.show = "yes"

        lv.dataTaskString = lv.dataTask + "_" + lv.slug

        lv.thisVue.id = globals.api_data[lv.dataTaskString].item.id
        lv.thisVue.course_title = globals.api_data[lv.dataTaskString].item.course_title
        lv.thisVue.course_slug = globals.api_data[lv.dataTaskString].item.course_slug
        lv.thisVue.title = globals.api_data[lv.dataTaskString].item.title
        lv.thisVue.description = globals.api_data[lv.dataTaskString].item.description
        //lv.thisVue.text = globals.api_data[lv.dataTaskString].item.text
        lv.thisVue.img = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].item.header_image].file
        lv.thisVue.percentage = globals.api_data[lv.dataTaskString].item.percentage

        lv.thisVue.color_course = globals.api_data[lv.dataTaskString].item.course_color
        if(!lv.thisVue.color_course){
          lv.thisVue.color_course = '#00d45b'
        }

        lv.thisVue.lesson_homeObj = globals.api_data[lv.dataTaskString].item
        lv.thisVue.lesson_homeObj.img = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].item.header_image].file
        lv.thisVue.lesson_homeObj.text = globals.api_data[lv.dataTaskString].item.text
        lv.thisVue.lesson_homeObj.time_string = globals.api_data[lv.dataTaskString].item.hours_to_study + ' uur'
        lv.thisVue.lesson_homeObj.preview_mode = lv.thisVue.preview_mode

        lv.course_data = {
          course_id: globals.api_data[lv.dataTaskString].item.course_id,
          course_slug: globals.api_data[lv.dataTaskString].item.course_slug,
          course_title: globals.api_data[lv.dataTaskString].item.course_title,
          lesson_id: globals.api_data[lv.dataTaskString].item.id,
          lesson_slug: globals.api_data[lv.dataTaskString].item.slug,
          lesson_title: globals.api_data[lv.dataTaskString].item.title
        }

        lv.thisVue.lesson_endObj.type_name = 'einde'
        lv.thisVue.lesson_endObj.text = globals.api_data[lv.dataTaskString].item.lesson_finished_text
        lv.thisVue.lesson_endObj.course_data = lv.course_data
        lv.thisVue.lesson_endObj.other_lessons_data = globals.api_data[lv.dataTaskString].item.other_lessons_data
        lv.thisVue.lesson_endObj.preview_mode = lv.thisVue.preview_mode

        lv.nav_parts = []
        lv.nav_parts.push({
          name: 'Introductie',
          index: 0,
          usable: true
        })

        lv.last_block_done = null

        lv.transpose = []
        lv.prev_usable = false
        for (let key in globals.api_data[lv.dataTaskString].blocks) {
          lv.nav_total++
          lv.block = []
          lv.block.id = globals.api_data[lv.dataTaskString].blocks[key].id
          lv.block.type = globals.api_data[lv.dataTaskString].blocks[key].type
          lv.block.title = globals.api_data[lv.dataTaskString].blocks[key].title
          lv.block.name = globals.api_data[lv.dataTaskString].blocks[key].name
          lv.block.done = globals.api_data[lv.dataTaskString].blocks[key].done

          if(lv.block.done){
            lv.last_block_done = lv.nav_total
          }

          lv.nav_part_block = {}
          lv.nav_part_block['name'] = lv.block.name
          lv.nav_part_block['index'] = lv.nav_total
          lv.nav_part_block['usable'] = lv.block.done
          if(lv.prev_usable){
            lv.nav_part_block['usable'] = true
          }
          if(lv.block.done){
            lv.prev_usable = true
          }else{
            lv.prev_usable = false
          }
          lv.nav_parts.push(lv.nav_part_block)
          lv.anchorID = '#section_' + lv.block.id
          lv.block.lesson_slug = lv.slug
          lv.block.blockObj = globals.api_data[lv.dataTaskString].blocks[key]
          lv.block.blockObj.type = globals.api_data[lv.dataTaskString].blocks[key].type
          lv.block.blockObj['text'] = globals.api_data[lv.dataTaskString].blocks[key].text
          lv.block.blockObj['index_id'] = lv.nav_total
          lv.block.blockObj['preview_mode'] = lv.thisVue.preview_mode


          lv.block.blockObj['course_data'] = lv.course_data

          switch (lv.block.type) {
            case 'image':
              lv.block.blockObj['file'] = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].blocks[key].block_image_picture].file
              break
            case 'video':
              if(globals.api_data[lv.dataTaskString].blocks[key].block_video_file){
                lv.block.blockObj['file'] = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].blocks[key].block_video_file].file
              }
              break
            default:
              break
          }
          lv.transpose.push(lv.block)
        }
        lv.thisVue.setBlocks(lv.transpose)


        lv.end_usable = false
        if(lv.nav_parts[lv.nav_total].usable){
          lv.end_usable = true
        }
        lv.nav_total++
        lv.nav_parts.push({
          name: 'Tot slot',
          index: lv.nav_total,
          usable: lv.end_usable
        })
        lv.thisVue.setSideBar(lv.nav_parts)

        lv.thisVue.$nextTick(function () {
          lv.targ = '#sidebar_lesson'
          gsap.set(lv.targ, {x: '-100%'})
          setTimeout(function(){
            //curtain_open()
          },100)

          if(globals.nav_from_takeaways > 0 ){

            lv.subnavdest = 1
            lv.subdest = globals.nav_from_takeaways
            lv.nr = 0
            while(lv.nr < this.blocks.length){
              if(this.blocks[lv.nr].id == lv.subdest){
                lv.subnavdest = lv.nr + 1
              }
              lv.nr++
            }
            this.navigate_lesson(lv.subnavdest)

            globals.nav_from_takeaways = 0
            return
          }

          lv.subdestblock = lv.last_block_done + 1
          console.log('total blocks: ' + lv.nav_total)
          console.log('last block done: ' + lv.last_block_done)
          if(lv.subdestblock >= lv.nav_total){
            return
          }
          if(lv.last_block_done){
            this.navigate_lesson(lv.subdestblock)
          }

          
        })
      }
    }
    
  },
  methods: {
    setBlocks(blox){
      this.blocks = blox
    },
    setSideBar(nav){
      this.sidebar.nav = nav
      this.sidebar.nav[1].usable = true
      this.sidebar.color = lv.thisVue.color_course
    },
    navigate_lesson(dest){
      lesson_set_nav(dest)
    },
    lesson_sidebar(){
      if(lv.sidebar_busy){
        return
      }
      lv.sidebar_busy = true
      lv.targ = '#sidebar_lesson'
      if(this.sidebar_active){
        gsap.to(lv.targ, {duration:0.4, display: 'none', x: '-100%', ease:'power3.in', onComplete: this.sidebar_done})
        this.sidebar_active = false
      }else{
        gsap.to(lv.targ, {duration:0.4, display: 'block', x: '0', ease:'power3.out', onComplete: this.sidebar_done})
        this.sidebar_active = true
        localStorage.setItem('sidebar_hint', true)
        this.show_sidebar_hint = false
      }
    },
    sidebar_done(){
      lv.sidebar_busy = false
    },
    sidebar_nav(who){
      if(lv.nav_cur != who){
        lesson_set_nav(who)
      }
    },
    sidebar_blur(){
      console.log('sidebar blur')
      if(this.sidebar_active){
        this.lesson_sidebar()
      }
    },
    admin_allow(){
      if(this.preview_mode){
        if(globals.admin_user){
          this.allowed = true
        }else{
          this.allowed = false
        }
      }
    },
    checkPreviewMode(){
      if(this.$route.name === 'PreviewLesson'){
        control_main.console_log('_______ Admin Preview Mode _______')
        this.preview_mode = true
      }else{
        this.preview_mode = false
      }
    }
  },
  updated(){
    
  }
}

function nav_next_disable(){
  lv.thisVue.nav_next_disabled = true
  lv.thisVue.opac_nav_next = 'opacity:0.4;'
}
function nav_next_enable(){
  lv.thisVue.nav_next_disabled = false
  lv.thisVue.opac_nav_next = ''
}

function curtain_close(){
  lv.targ = '#lesson_curtain'
  gsap.to(lv.targ,{duration:0.3, opacity:1, display: 'block', ease:'power3.inOut'})
}
function curtain_open(){
  if(globals.api_data['lesson_fetch_' + lv.slug].blocks[lv.nav_cur]){
    EventBus.$emit('lesson_part_reveal',globals.api_data['lesson_fetch_' + lv.slug].blocks[lv.nav_cur].id)
  }
  lv.targ = '#lesson_curtain'
  gsap.to(lv.targ,{duration:0.3, opacity:0, display: 'none', ease:'power3.inOut'})

  bookmark_float_inactive()
}

lv.nav_dest = ''
function lesson_set_nav(dest){
  //control_main.console_log('go here: ' + dest + ', from here: ' + lv.nav_cur)

  bookmark_float_inactive()

  nav_next_disable()

  lv.nav_dest = dest

  if(dest === '+1'){
    lv.tempnav = lv.nav_cur
    lv.tempnav++
    lv.nav_dest = lv.tempnav
  }
  if(dest === '-1'){
    lv.tempnav = lv.nav_cur
    lv.tempnav--
    lv.nav_dest = lv.tempnav
  }
  if(dest === 'start'){
    lv.nav_dest = 0
  }
  if(lv.nav_dest === 0){
    nav_next_enable()
  }

  curtain_close()


  if(lv.nav_dest > 0 && lv.nav_dest < lv.nav_total){
    lv.block_item_done = globals.api_data['lesson_fetch_' + lv.slug].blocks[lv.nav_dest].done
    if(lv.block_item_done){
      nav_next_enable()
    }
  }

  lv.window_scroll = window.pageYOffset
  if(lv.window_scroll > 150){
    scroll_up()
  }

  lv.thisVue.sidebar_active_item = lv.nav_dest + 1
  lv.nav_cur = lv.nav_dest

  gsap.delayedCall(0.3, lesson_nav,[lv.nav_dest])
  gsap.delayedCall(0.3, curtain_open)
}

function lesson_nav(dest){

  if(dest >= lv.nav_total){
    lv.thisVue.show_nav_next = false
  }else{
    lv.thisVue.show_nav_next = true
  }
  if(dest == 0){
    lv.thisVue.show_nav_prev = false
  }else{
    lv.thisVue.show_nav_prev = true
  }

  if(dest === 0){
    lv.targ = '#lesson_intro'
    gsap.set(lv.targ,{display:'block'})
    lv.targ = '#lesson_content'
    gsap.set(lv.targ,{display:'none'})
    lv.targ = '#lesson_end'
    gsap.set(lv.targ,{display:'none'})
  }
  if(dest > 0 && dest < lv.nav_total){
    lv.targ = '#lesson_intro'
    gsap.set(lv.targ,{display:'none'})
    lv.targ = '#lesson_content'
    gsap.set(lv.targ,{display:'block'})
    lv.targ = '.lesson_section'
    gsap.set(lv.targ,{display:'none'})
    lv.targ = '#section_' + dest
    gsap.set(lv.targ,{display:'block'})
    lv.targ = '#lesson_end'
    gsap.set(lv.targ,{display:'none'})
  }
  if(dest === lv.nav_total){
    EventBus.$emit('lesson_finished')
    lv.targ = '#lesson_end'
    gsap.set(lv.targ,{display:'block'})
    lv.targ = '#lesson_content'
    gsap.set(lv.targ,{display:'none'})
    lv.targ = '#lesson_intro'
    gsap.set(lv.targ,{display:'none'})
  }

}

function scroll_up(){
    lv.targ = '#lesson_nav_wrapper'
    gsap.to(window, {duration: 0.4, scrollTo: {y: 171, offsetY: 50}, ease:'power3.inOut'})
}

EventBus.$on('lesson_nav', (e) => {
  lv.go = e
  if(e == 'start'){
    lv.go = 1
  }
  lesson_set_nav(lv.go)
})

lv.setY = 0
lv.bookmark_float_target = null

EventBus.$on('bookmark_selection', (e) => {

  //return

  //console.log('nav cur: ' + lv.nav_cur)

  lv.selection = null
  lv.selection_html = null

  if (window.getSelection) {
    lv.selection = window.getSelection()
  } else if (document.selection) {
    lv.selection = document.selection.createRange()
  }

  if (!lv.selection || lv.selection.rangeCount < 1) {
    return
  }

  if (window.getSelection) {
    var div = document.createElement('div')
    div.appendChild(lv.selection.getRangeAt(0).cloneContents())
    lv.selection_html = div.innerHTML
  } else if (document.selection) {
    lv.selection_html = lv.selection.htmlText
  }

  if (!lv.selection.isCollapsed) {
    lv.selected_string = lv.selection.toString()
    lv.prohibit = lv.selection_html.includes('wysiwyg_field')
    if(!lv.prohibit){
      if(lv.selected_string !== ''){
        //console.log(lv.selection)
        //console.log(lv.selection_html)

        lv.selection_stripped_from_html = lv.selected_string.replace(/(<([^>]+)>)/gi, "");
        //console.log(lv.selection_stripped_from_html)

        if(lv.selection_stripped_from_html.length < 5){
          //console.log('too small')
          return
        }

        lv.selection_html = lv.selection_html.replace(/<img[^>]*>/g,"")
        lv.selection_html = lv.selection_html.replace(/<iframe[^>]*>/g,"")

        //globals.selected_text = lv.selection.toString()
        globals.selected_text = lv.selection_html
        //lv.parent_y = document.getElementById('bookmark_float').parentElement.offsetTop

        lv.t = 1
        if(lv.nav_cur){
          lv.t = lv.nav_cur
        }
        lv.bookmark_float_target = document.getElementById('section_' + lv.t).getElementsByClassName('lesson_blocks_wrapping')[0]
        lv.parentRect = lv.bookmark_float_target.getBoundingClientRect()
        lv.selectionRect = lv.selection.getRangeAt(0).getBoundingClientRect()


        lv.selectionWrapRect = document.getElementById('section_' + lv.t).getElementsByClassName('bookmarkable')[0].getBoundingClientRect()
        console.log(lv.selectionRect)
        console.log(lv.selectionWrapRect)

        if(lv.selectionRect.top < lv.selectionWrapRect.top){
          console.log('selection bookmark not allowed')
          return
        }
        if(lv.selectionRect.bottom > lv.selectionWrapRect.bottom){
          console.log('selection bookmark not allowed')
          return
        }

        lv.pos1 = lv.selectionRect.top - lv.parentRect.top
        lv.setY = lv.pos1 + (lv.selectionRect.height * 0.5) - 5

        gsap.delayedCall(0.2,bookmark_float_active)
      }
    }
  }

})

document.onselectionchange = userSelectionChanged
function userSelectionChanged() {
  if(globals.bookmark_float_visible){
    gsap.delayedCall(0.2,bookmark_float_inactive)
  }
}

function bookmark_float_active(){
  lv.t = 1
  if(lv.nav_cur){
    lv.t = lv.nav_cur
  }
  lv.targ = document.getElementById('section_' + lv.t).getElementsByClassName('floatingbookmark')[0]
  gsap.set(lv.targ,{top:lv.setY + 'px', display: 'block'})
  globals.bookmark_float_visible = true
}
function bookmark_float_inactive(){
  console.log('bookmark_float_inactive')
  globals.bookmark_float_visible = false
  gsap.set('.floatingbookmark',{display: 'none'})

  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  }  else if (document.selection) {
    document.selection.empty()
  }
}

EventBus.$on('bookmark_selection_hide', (e) => {
    bookmark_float_inactive()
})




</script>



<style scoped lang="scss">

.lesson_nav_wrap{
  min-height: 100px;
}
.radiotext{
  max-height: 50;
  overflow: hidden;
}

.tooltip_wrap{
  position: absolute;
  font-size: 15px;
  left: -39px;
  top: 56px;
}
.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
  position: absolute;
  left: calc(50% - 7px);
  top:-7px;
}
.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; 
  border-right:7px solid black; 
  position: absolute;
  left: -7px;
  top:12px;
  display: none;
}

@media (max-width: 1280px){
  .tooltip_wrap{
    left: 7px;
  }
  .arrow-up {
    left: 11px;
  }
}
@media (max-width: 720px){
  .tooltip_wrap{
    top: 47px;
  }
}

@media (max-width: 480px){
  .tooltip_wrap{
    left: 66px;
    top: 6px;
  }
  .arrow-up {
    display: none;
  }
  .arrow-left {
    display: block;
  }
}

</style>
