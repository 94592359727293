<template>
  <div class="hidden lesson_section" v-bind:class="color_bg" :id="indexID" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
  <!--  
  <div class="hidden lesson_section" v-bind:class="color_bg" :id="indexID">
  -->

    <!--  
    <div class="spacer_v_lesson_block"></div>
    -->


    <div class="mode-center">
        <div class="block_hdr_pad">
          <div class="text-body-1 text-white breadcrumb breadcrumb_block">
            <router-link :to="course_url" exact class="inline-block relative hover:underline">{{blockObj.course_data.course_title}}</router-link>
             > {{blockObj.course_data.lesson_title}}
          </div>
        </div>
        <div class="relative lesson_blocks_wrapping">

          <question_open :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'question'" />
          <multiplechoice :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'multiplechoice'" />
          <rangeslider :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'rangeslider'" />
          <blockimage :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'image'" />
          <blockvideo :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'video'" />
          <textfield :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'textfield'" />
          <howto :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'howto'" />

          <blockquiz :div_id="indexID" :type="type" :blockObj="blockObj" v-if="blocktype === 'quiz'" />

          <div class="vspace_lesson_block_bottom"></div>

          <div class="floatingbookmark hidden">
            <div class="pos-center">
              <div class="rounded-full bg-lemon ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out relative" @click="bookmark_float_show_modal">
                <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </div>
            </div>
          </div>

        </div>

    </div>


  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'

import gsap from 'gsap'

import question_open from '@/components//blocks/question_open.vue'
import multiplechoice from '@/components//blocks/multiplechoice.vue'
import rangeslider from '@/components//blocks/rangeslider.vue'
import blockimage from '@/components//blocks/blockimage.vue'
import blockvideo from '@/components//blocks/blockvideo.vue'
import textfield from '@/components//blocks/textfield.vue'
import howto from '@/components//blocks/howto.vue'

import blockquiz from '@/components//blocks/blockquiz.vue'

var lv = {}
lv.thisVure = null

export default {
  name: 'lesson_block',
  props: ['id','lesson_id','type','title','index','blockObj'],
  components: {
    question_open,
    multiplechoice,
    rangeslider,
    blockimage,
    blockvideo,
    textfield,
    howto,
    blockquiz
  },
  data() {
    return {
      blocktype: null,
      indexID: 'section_',
      color_bg: '',
      course_url: '/cursussen/'
    }
  },
  mounted() {
    this.blocktype = this.$props.type
    this.indexID = 'section_' + this.$props.id

    this.course_url = '/cursussen/' + this.blockObj.course_data.course_slug
    if(this.blockObj.preview_mode){
      this.course_url = '/preview/cursussen/' + this.blockObj.course_data.course_slug
    }

    lv.types_black = ['question','multiplechoice','rangeslider','dropdown']

    if(lv.types_black.includes(this.blocktype)){
      this.color_bg = 'bg-black'
    }
    
  },
  methods: {
    bookmark_float_show_modal(){
      console.log('bookmark float')
      lv.modal_data = {
        type: 'selection',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      EventBus.$emit('bookmark_selection')
    }   
  }
}

</script>

<style lang="scss">

.block_hdr_pad{
  padding:50px 5px 50px 5px;
}
.breadcrumb_block{
  font-size: 17px;
  opacity: 0.9;
}

#bookmark_float{
  width:0px;
  height: 0px;
}

</style>
