<template>

  <div class="fixed w-full h-full top-0 left-0 z-50 hidden opacity-0" id="modal_lesson"> 
    <div class="pos-g bg-black opacity-90"></div>
    <div class="pos-g bg-pattern-1 opacity-10"></div>
      <div class="w-full h-full overflow-y-scroll relative">
        <div class=" relative flex items-center justify-center min-h-full w-full p-7">
          <div class="pos-g" @click="hide"></div>
          <div class="p-4 w-full">        
            <div class="rounded-lg bg-blue-ice py-8 px-20 w100 shadow-custom relative maxwxlg overflow-hidden" id="modal_lesson_content">

              <div id="modal_lesson_wrap">

                <div class="" id="modal_lesson_main">
                  <div class="text-hdr-2 break-words pb-6 pr-10" id="modal_lesson_title">
                    {{modal_title}}
                  </div>              
                  <div id="modal_lesson_body">

                    <div class="pb-4">
                      <div class="grid grid-cols-2 gap-4 gap-y-2 mxmd:grid-cols-1">
                        <div class="q">
                          <div class="uppercase text-micro-4">
                            cursus
                          </div>
                          <div class="text-body-1">
                            {{name_course}}
                          </div>
                        </div>

                        <div class="q">
                          <div class="uppercase text-micro-4">
                            onderdeel
                          </div>
                          <div class="text-body-1">
                            {{name_block}}
                          </div>
                        </div>

                        <div class="q">
                          <div class="uppercase text-micro-4">
                            les
                          </div>
                          <div class="text-body-1">
                            {{name_lesson}}
                          </div>
                        </div>

                        <div class="q">
                          <div class="uppercase text-micro-4">
                            datum
                          </div>
                          <div class="text-body-1">
                            {{date}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pb-8" v-show="type == 'selection'">

                      <div class="uppercase text-micro-4 py-4">
                        tekst die je op wilt slaan
                      </div>

                      <div class="rounded-md bg-grey-med text-subhdr-2 p-5 wysiwyg_text" v-html="selected_text">
                      </div>

                    </div>

                    <div class="uppercase text-micro-4 py-4">
                      waarom sla je dit op als takeaway? (max x aantal tekens)
                    </div>

                    <div class="pb-6">
                      <textarea rows="4" class="resize-none rounded-md bg-white text-mini-1 padding_lesson_block_3 w100 focus:outline-none focus:shadow-outline" v-model="note" maxlength="800"></textarea> 
                    </div>

                    <div class="vspacer"></div>

                    <div class="mnxl:flex justify-between items-center">
                      <div class=" order-last pb-4 flex-shrink-0">
                        <btn_default text="sla op in mijn takeaways" type="wide" :state="btn_disabled" @btn-click="bookmark_save"  />
                      </div>
                      <div class="text-body-2 text-orange-pastel cursor-pointer hover:text-black pb-4" @click.prevent="hide">
                        annuleren
                      </div>
                    </div>

                  </div>
                  <!--  
                  <div class="absolute top-0 left-0 pointer-events-none" style="opacity:0.3;">
                    <img src="@/assets/img/temp/modal_lesson_2.jpg" />
                  </div>
                  -->
                </div>

                <div class="hidden text-center" id="modal_lesson_finish">
                  <div class="text-hdr-3 py-8">
                    Je Takeaway is succesvol opgeslagen!
                  </div>
                  <div class="inline-block">
                    <!--  
                    <img src="@/assets/img/placeholder.png" class="maxwi" />
                    -->
                    <img src="@/assets/img/icons_graphic/takeaway.png" class="maxwi" />
                  </div>
                  <div class="pb-8"></div>
                  <div class="inline-block">
                    <btn_default text="ga door met de les" type="wide" @btn-click="hide"  />
                  </div>
                  <div class="pb-6"></div>
                  <div class="inline-block">

                    <a href="/takeaways" class="text-subhdr-3 block lesson_btn rounded-md px-2 pt-1 pb-2 hover:bg-black hover:text-white transition duration-200 ease-in-out" >
                      <div class="inline-block align-middle mr-2 font-bold">                   
                        naar takeaways
                      </div>
                      <div class="inline-block align-middle">                    
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                      </div>
                    </a>

                  </div>
                  <div class="pb-8"></div>
                </div>

              </div>

              <div class="absolute left-0 top-0" v-show="type == 'bookmark'">
                <bookmark_icon type="bookmark" />
              </div>
              <div class="absolute left-0 top-0" v-show="type == 'selection'">
                <bookmark_icon type="selection" />
              </div>
              <div class="absolute right-0 top-0 p-5" id="modal_lesson_btn">
                <button
                  class="bg-blue-ice text-black hover:bg-black hover:text-white py-2 px-2 rounded-full focus:outline-none focus:bg-black transition duration-300 ease-in-out"
                  @click.prevent="hide"
                >
                  <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
              </div>

            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import gsap from 'gsap'

import btn_default from '@/components/ui/btn_default.vue'
import bookmark_icon from '@/components/misc/bookmark_icon.vue'

var lv = {}
lv.thisVue = null
lv.body = document.getElementsByTagName('BODY')[0]

lv.course_id = 0
lv.lesson_id = 0
lv.block_id = 0
lv.user_id = 0

export default {
  name: 'modal_lesson',
  components: {
    btn_default,
    bookmark_icon
  },
  props: [],
  data() {
    return {
      size: 'maxwxlg',
      modal_title: 'Sla op als takeaway',
      modal_body: 'modal lesson modal lesson modal lesson modal lesson modal lesson modal lesson modal lesson ',
      note: null,
      btn_disabled: false,
      date: null,
      name_course: null,
      name_block: null,
      name_lesson: null,
      type: 'bookmark',
      selected_text: null
    }
  },
  mounted() {

    lv.thisVue = this
    
  },
  methods:{
    hide(){
      modal_lesson_hide()
    },
    bookmark_save(){
      console.log('bookmark_save')
      lv.ob = {
        course_id: lv.course_id,
        lesson_id: lv.lesson_id,
        block_id: lv.block_id,
        user_id: lv.user_id,
        type: this.type,
        takeaway_data: {
          selected_text: this.selected_text,
          note: this.note,
          date: this.date,
          name_course: this.name_course,
          slug_course: this.slug_course,
          name_lesson: this.name_lesson,
          slug_lesson: this.slug_lesson,
          name_block: this.name_block
        }
      }
      bookmark_send(lv.ob)
    }
  },
  destroyed(){
    gsap.delayedCall(0.5, switchOverflowOut)
  },
}

EventBus.$on('modal_lesson_open', (e) => {
   modal_lesson_populate(e)
   modal_lesson_show()
})

function today(){
  lv.today = new Date();
  lv.dd = String(lv.today.getDate()).padStart(2, '0');
  lv.mm = String(lv.today.getMonth() + 1).padStart(2, '0');
  lv.yyyy = lv.today.getFullYear();
  lv.today = lv.dd + '.' + lv.mm + '.' + lv.yyyy;
  return lv.today
}

function modal_lesson_mode_reset(){
  lv.thisVue.btn_disabled = false
  lv.thisVue.note = null
  gsap.set('#modal_lesson_main',{display: 'block', opacity: 1})
  gsap.set('#modal_lesson_finish',{display: 'none'})
}


function modal_lesson_populate(e){
  modal_lesson_mode_reset()

  if(e.type){
    lv.thisVue.type = e.type
  }
  if(e.type == 'selection'){
    lv.thisVue.selected_text = globals.selected_text
  }

  lv.thisVue.date = today()
  lv.thisVue.name_course = e.meta.course_title
  lv.thisVue.slug_course = e.meta.course_slug
  lv.thisVue.name_block = e.block.name
  lv.thisVue.name_lesson = e.meta.title
  lv.thisVue.slug_lesson = e.meta.lesson_slug

  lv.course_id = e.meta.course_id
  lv.lesson_id = e.meta.id
  lv.block_id = e.block.id
  lv.user_id = globals.user_data.id
}

function modal_lesson_show(){
  
  EventBus.$emit('bookmark_selection_hide')

  lv.tl = gsap.timeline()
  lv.targ = '#modal_lesson'
  lv.tl.set(lv.targ,{display: 'block'})
  lv.targ = '#modal_lesson_content'
  lv.tl.set(lv.targ,{opacity: 0, scale:0.95})
  lv.targ = '#modal_lesson_title'
  lv.tl.set(lv.targ,{opacity: 0, y: 10})
  lv.targ = '#modal_lesson_body'
  lv.tl.set(lv.targ,{opacity: 0, y: 5})
  lv.targ = '#modal_lesson_btn'
  lv.tl.set(lv.targ,{opacity: 0, scale: 0.1})

  lv.tl.call(switchOverflowIn)
  lv.targ = '#modal_lesson'
  lv.tl.to(lv.targ, {duration: 2, opacity:1, ease:'power3.inOut'})
  lv.targ = '#modal_lesson_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, scale: 1, ease:'power3.inOut'},'-=1')
  lv.targ = '#modal_lesson_title'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_lesson_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_lesson_btn'
  lv.tl.to(lv.targ, {duration: 3, opacity:1, scale: 1, ease:'elastic.out'} ,'-=0.5')
  lv.tl.timeScale(3)

}

function modal_lesson_hide(){
  lv.tl = gsap.timeline()
  lv.targ = '#modal_lesson_btn'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'expo.inOut'})
  lv.targ = '#modal_lesson_title'
  lv.tl.to(lv.targ, {duration: 1, opacity: 0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_lesson_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_lesson_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, scale: 0.95, ease:'power3.inOut'},'-=0.5')
  lv.targ = '#modal_lesson'
  lv.tl.to(lv.targ, {duration: 2, opacity:0, display: 'none', ease:'power3.inOut'},'-=0.5')
  lv.tl.call(switchOverflowOut)
  lv.tl.call(modal_lesson_closed)
  lv.tl.timeScale(3)
}

function switchOverflowIn(){
  lv.body_width_offset = getScrollbarCompensation()
  lv.body.style.overflowY = 'hidden'
  lv.body.style.paddingRight = lv.body_width_offset + 'px'
}
function switchOverflowOut(){
  lv.body.style.overflowY = 'scroll'
  lv.body.style.paddingRight = 0
}

function getScrollbarCompensation() {
  lv.start_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'hidden'
  lv.end_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'scroll'
  return lv.end_width - lv.start_width
}

function modal_lesson_closed(){
  EventBus.$emit('modal_lesson_closed')
}

lv.dataTask = "takeaway_set"
lv.dataEvent = "data_takeaway_set"
function bookmark_send(ob){
  lv.thisVue.btn_disabled = false
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: ob
  })
}

function dataLoaded(d) {
  lv.thisVue.btn_disabled = true
  if (d.result == "nok") {
    console.warn(d)
    return
  }
  if (d.result == "ok") {
    console.log('takeaway saved')
    //lv.thisVue.hide()
    modal_lesson_finish()
  }
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e);
})

function modal_lesson_finish(){

  /*
  gsap.set('#modal_lesson_main',{display: 'none'})
  gsap.set('#modal_lesson_finish',{display: 'block'})
  */

  lv.targ = '#modal_lesson_wrap'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#modal_lesson_wrap'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_lesson_main'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_lesson_finish'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#modal_lesson_wrap'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_lesson_finish'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_lesson_main'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#modal_lesson_wrap'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#modal_lesson_main'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#modal_lesson_wrap'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#modal_lesson_finish'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#modal_lesson_wrap'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

}


</script>

<style lang="scss" scoped>

.maxwlg{
  max-width: 1040px;
}
.maxwsm{
  max-width: 580px;
}
.shadow-custom{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.bg-pattern-1{
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%232f2f2f' fill-opacity='0.5' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.modal_lesson_info{
  margin-right: 20px;

}
.vspacer{
  width: 100vw;
}

.maxwxlg{
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
}
.maxwi{
  width:100%;
  max-width: 440px;
}

</style>
