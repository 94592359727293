<template>

  <div class="fixed w-full h-full top-0 left-0 z-50 hidden opacity-0" id="modal_takeaways"> 
    <div class="pos-g bg-black opacity-90"></div>
    <div class="pos-g bg-pattern-1 opacity-10"></div>
      <div class="w-full h-full overflow-y-scroll relative">
        <div class=" relative flex items-center justify-center min-h-full w-full p-7">
          <div class="pos-g" @click="hide"></div>
          <div class="p-4 w-full">        
            <div class="rounded-lg bg-blue-ice py-8 px-20 mxxsm:px-12 w100 shadow-custom relative maxwxlg overflow-hidden" id="modal_takeaways_content">

              <div id="modal_takeaways_wrap">

                <div class="" id="modal_takeaways_main">
                  <div id="modal_takeaways_body">

                    <div v-show="type == 'note'">


                      <div v-show="modal_mode == 'add'">

                        <div class="text-hdr-8 font-extrabold break-words pb-6 pr-10" id="modal_takeaways_title">
                          {{modal_title}}
                        </div>   

                        <div class="pb-5">
                          <div class="uppercase text-micro-4">
                            datum
                          </div>
                          <div class="text-body-1">
                            {{date}}
                          </div>
                        </div>
                                  
                        <div class="pb-5">
                          <div class="uppercase text-micro-4 pb-2">
                            onderwerp
                          </div>
                          <input type="text" class="resize-none rounded-md bg-white text-mini-1 p-3 w100 focus:outline-none focus:shadow-outline" v-model="subject" maxlength="50">
                        </div>
                        
                        <div class="hspacer"></div>
                                  
                                    
                        <div class="pb-5">
                          <div class="uppercase text-micro-4 pb-2">
                            jouw notitie
                          </div>
                          <textarea rows="4" class="resize-none rounded-md bg-white text-mini-1 padding_lesson_block_3 w100 focus:outline-none focus:shadow-outline" v-model="note" maxlength="800"></textarea> 
                        </div>

                        <div class="pb-16"></div>

                        <div class="mnxl:flex justify-between items-center">
                          <div class=" order-last pb-4 flex-shrink-0">
                            <btn_default text="sla op in mijn takeaways" type="wide" :state="btn_disabled" @btn-click="bookmark_save"  />
                          </div>
                          <div class="text-body-2 text-orange-pastel cursor-pointer hover:text-black transition duration-300 ease-in-out pb-4" @click.prevent="hide">
                            annuleren
                          </div>
                        </div>

                      </div>

                      <div v-show="modal_mode == 'view'">

                        <div class="padtop">
                        </div>              

                        <div class="pb-4">
                          <div class="grid grid-cols-2 gap-4 gap-y-2 mxmd:grid-cols-1"> 
                            <div class="q">
                              <div class="uppercase text-micro-4">
                                toegevoegd op
                              </div>
                              <div class="text-body-1">
                                {{date}}
                              </div>
                            </div>

                            <div class="q">
                              <div class="uppercase text-micro-4">
                                onderwerp
                              </div>
                              <div class="text-body-1">
                                {{subject}}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div v-show="note != null">
                          <div class="uppercase text-micro-4 py-4">
                            notitie
                          </div>

                          <div class="pb-6 text-subhdr-3">
                            {{note}}
                          </div>
                        </div>

                        <div class="vspacer"></div>
                        <div class="hspacer"></div>
                        <div class="hspacer" v-show="type == 'bookmark'"></div>

                        <div class="mnxl:flex justify-between items-center">
                          <div class="text-body-2 text-orange-pastel cursor-pointer hover:text-black transition duration-300 ease-in-out pb-4" @click.prevent="bookmark_unset">
                            <div class="inline-block mr-2 relative iconremove">
                              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </div>
                            Deze takeaway verwijderen
                          </div>
                        </div>
                        
                      </div>


                   </div>

                    <div v-show="type != 'note'">

                      <div class="padtop">
                      </div>              

                      <div class="pb-4">
                        <div class="grid grid-cols-2 gap-4 gap-y-2 mxmd:grid-cols-1"> 
                          <div class="q">
                            <div class="uppercase text-micro-4">
                              cursus
                            </div>
                            <div class="text-body-1">
                              {{name_course}}
                            </div>
                          </div>

                          <div class="q">
                            <div class="uppercase text-micro-4">
                              onderdeel
                            </div>
                           <div class="text-body-1">
                              {{name_block}}
                            </div>
                          </div>

                          <div class="q">
                            <div class="uppercase text-micro-4">
                              les
                            </div>
                            <div class="text-body-1">
                              {{name_lesson}}
                            </div>
                          </div>

                          <div class="q">
                            <div class="uppercase text-micro-4">
                              datum
                            </div>
                            <div class="text-body-1">
                              {{date}}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="pb-8" v-show="type == 'selection'">

                        <div class="uppercase text-micro-4 py-4">
                          gemarkeerde tekst
                        </div>

                        <div class="text-subhdr-3 wysiwyg_text" v-html="selected_text">
                        </div>

                        <div class="hspacer"></div>
                      </div>

                      <div v-show="note != null">
                        <div class="uppercase text-micro-4 py-4">
                          dit onderdeel is opgeslagen omdat
                        </div>

                        <div class="pb-6 text-subhdr-3">
                          {{note}}
                        </div>
                      </div>

                      <div class="vspacer"></div>
                      <div class="hspacer"></div>
                      <div class="hspacer" v-show="type == 'bookmark'"></div>

                      <div class="mnxl:flex justify-between items-center">
                        <div class=" order-last pb-4 flex-shrink-0">
                          <btn_default text="ga naar dit onderdeel" type="wide" :state="btn_disabled" @btn-click="takeaway_navigate"  />
                        </div>
                        <div class="text-body-2 text-orange-pastel cursor-pointer hover:text-black transition duration-300 ease-in-out pb-4" @click.prevent="bookmark_unset">
                          <div class="inline-block mr-2 relative iconremove">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                          </div>
                          Deze takeaway verwijderen
                        </div>
                      </div>

                    </div>



                  </div>

                </div>

                <div class="hidden text-center" id="modal_takeaways_finish">
                  <div class="text-hdr-3 py-8">
                    Je Takeaway is verwijderd.
                  </div>
                  <div class="pb-8"></div>
                  <div class="inline-block">
                    <btn_default text="ok" type="wide" @btn-click="hide"  />
                  </div>
                  <div class="pb-8"></div>
                </div>

                <div class="hidden text-center" id="modal_takeaways_finish_set">
                  <div class="text-hdr-3 py-8">
                    Je Takeaway is opgeslagen.
                  </div>
                  <div class="pb-8"></div>
                  <div class="inline-block">
                    <btn_default text="ok" type="wide" @btn-click="hide"  />
                  </div>
                  <div class="pb-8"></div>
                </div>

              </div>

              <div class="absolute left-0 top-0" v-show="type == 'bookmark'">
                <bookmark_icon type="bookmark" />
              </div>
              <div class="absolute left-0 top-0" v-show="type == 'selection'">
                <bookmark_icon type="selection" />
              </div>
              <div class="absolute left-0 top-0" v-show="type == 'note'">
                <bookmark_icon type="note" />
              </div>

              <div class="absolute w-full navpos" v-show="modal_mode == 'view'">
                <div class="flex">

                  <div class="flex-shrink-0" id="modal_takeaways_prev">
                    <button @click.prevent="navprev" v-bind:class="[show_nav_prev ? '' : 'invisible']"
                    class="text-subhdr-3 block lesson_btn mt-1 rounded-md px-2 pt-1 mxmd:pt-2 pb-2 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
                      <div class="inline-block align-middle mr-2">   
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>                
                      </div>
                      <div class="inline-block align-middle font-bold">                   
                        vorige
                      </div>
                    </button>
                  </div>

                  <div class=" flex-grow"></div>

                  <div class="flex-shrink-0" id="modal_takeaways_next">
                    <button @click.prevent="navnext" v-bind:class="[show_nav_next ? '' : 'invisible']" 
                    class="text-subhdr-3 block lesson_btn mt-1 rounded-md px-2 pt-1 mxmd:pt-2 pb-2 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
                      <div class="inline-block align-middle font-bold mr-2">                   
                        volgende
                      </div>
                      <div class="inline-block align-middle">                    
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                      </div>
                    </button>
                  </div>

                </div>

              </div>

              <div class="absolute right-0 top-0 p-1" id="modal_takeaways_btn">
                <button
                  class="bg-blue-ice text-black hover:bg-black hover:text-white py-2 px-2 rounded-full focus:outline-none focus:bg-black transition duration-300 ease-in-out"
                  @click.prevent="hide"
                >
                  <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
              </div>

            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import gsap from 'gsap'

import btn_default from '@/components/ui/btn_default.vue'
import bookmark_icon from '@/components/misc/bookmark_icon.vue'

var lv = {}
lv.thisVue = null
lv.body = document.getElementsByTagName('BODY')[0]

lv.takeaway_id = 0
lv.index_cur = 0
lv.index_total = 0

export default {
  name: 'modal_takeaways',
  components: {
    btn_default,
    bookmark_icon
  },
  props: [],
  data() {
    return {
      size: 'maxwxlg',
      modal_title: 'notitie opslaan als takeaway',
      modal_body: '',
      note: null,
      subject: null,
      btn_disabled: false,
      date: null,
      id_block: null,
      name_course: null,
      name_block: null,
      name_lesson: null,
      type: 'bookmark',
      selected_text: null,
      lesson_url: null,
      modal_mode: 'view',
      show_nav_prev: true,
      show_nav_next: true
    }
  },
  mounted() {

    lv.thisVue = this
    
  },
  methods:{
    hide(){
      modal_takeaways_hide()
    },
    bookmark_save(){
      console.log('bookmark_save')
      lv.ob = {
        user_id: globals.user_data.id,
        type: this.type,
        takeaway_data: {
          subject: this.subject,
          note: this.note,
          date: this.date
        }
      }
      bookmark_send(lv.ob)
    },
    bookmark_unset(){
      console.log('bookmark_unset')
      lv.ob = {
        takeaway_id: lv.takeaway_id
      }
      bookmark_remove(lv.ob)
    },
    takeaway_navigate(){
      globals.nav_from_takeaways = this.id_block
      this.$router.push({ path: this.lesson_url }) 
    },
    navprev(){
      this.navto('-1')
    },
    navnext(){
      this.navto('+1')
    },
    navto(n){
      if(n == '+1'){
        lv.index_dest = lv.index_cur + 1
        if(lv.index_dest == lv.index_total){
          lv.index_dest = 0
        }
        lv.ind = globals.api_data.takeaways_filtered[lv.index_dest].index
        modal_takeaways_populate('item_' + lv.ind)
      }
      if(n == '-1'){
        lv.index_dest = lv.index_cur - 1
        if(lv.index_dest < 0){
          lv.index_dest = lv.index_total
        }
        lv.ind = globals.api_data.takeaways_filtered[lv.index_dest].index
        modal_takeaways_populate('item_' + lv.ind)
     }
      lv.index_cur = lv.index_dest
      nav_stat()
    }

  },
  destroyed(){
    gsap.delayedCall(0.5, switchOverflowOut)
  },
}

EventBus.$on('modal_takeaways_open', (e, a) => {
    modal_takeaways_populate(e, a)
    modal_takeaways_show()
})

function nav_stat(){
  gsap.set('#modal_takeaways_prev',{display: 'block'})
  gsap.set('#modal_takeaways_next',{display: 'block'})

  if(lv.index_cur == 0){
    gsap.set('#modal_takeaways_prev',{display: 'none'})
  }
  if(lv.index_cur == lv.index_total - 1){
    gsap.set('#modal_takeaways_next',{display: 'none'})
  }
}

function today(){
  lv.today = new Date();
  lv.dd = String(lv.today.getDate()).padStart(2, '0');
  lv.mm = String(lv.today.getMonth() + 1).padStart(2, '0');
  lv.yyyy = lv.today.getFullYear();
  lv.today = lv.dd + '.' + lv.mm + '.' + lv.yyyy;
  return lv.today
}

function modal_takeaways_mode_reset(){
  lv.thisVue.btn_disabled = false
  lv.thisVue.note = null
  lv.thisVue.subject = null
  gsap.set('#modal_takeaways_main',{display: 'block', opacity: 1})
  gsap.set('#modal_takeaways_finish',{display: 'none'})
  gsap.set('#modal_takeaways_finish_set',{display: 'none'})
}

function modal_takeaways_populate(e, a){

  modal_takeaways_mode_reset()

  if(e == 'new'){
    lv.thisVue.type = 'note'
    lv.thisVue.modal_mode = 'add'
    lv.thisVue.date = today()
    return
  }

  lv.index_total = globals.api_data.takeaways_filtered.length
  lv.index_cur = a
  nav_stat()

  lv.thisVue.modal_mode = 'view'
  lv.takeaway_id = globals.api_data.takeaways[e].id
  lv.thisVue.type = globals.api_data.takeaways[e].type
  lv.thisVue.date = globals.api_data.takeaways[e].takeaway_data.date
  lv.thisVue.note = globals.api_data.takeaways[e].takeaway_data.note
  if(lv.thisVue.type == 'note'){
    lv.thisVue.subject = globals.api_data.takeaways[e].takeaway_data.subject
  }

  if(globals.api_data.takeaways[e].takeaway_data.selected_text){
    lv.thisVue.selected_text = globals.api_data.takeaways[e].takeaway_data.selected_text
  }

  if(lv.thisVue.type != 'note'){
    lv.thisVue.name_course = globals.api_data.takeaways[e].takeaway_data.name_course
    lv.thisVue.name_block = globals.api_data.takeaways[e].takeaway_data.name_block
    lv.thisVue.id_block = globals.api_data.takeaways[e].block_id
    lv.thisVue.name_lesson = globals.api_data.takeaways[e].takeaway_data.name_lesson
    lv.thisVue.lesson_url = '/cursussen/' + globals.api_data.takeaways[e].takeaway_data.slug_course + '/' + globals.api_data.takeaways[e].takeaway_data.slug_lesson
  }

}

function modal_takeaways_show(){
  
  lv.tl = gsap.timeline()
  lv.targ = '#modal_takeaways'
  lv.tl.set(lv.targ,{display: 'block'})
  lv.targ = '#modal_takeaways_content'
  lv.tl.set(lv.targ,{opacity: 0, scale:0.95})
  lv.targ = '#modal_takeaways_title'
  lv.tl.set(lv.targ,{opacity: 0, y: 10})
  lv.targ = '#modal_takeaways_body'
  lv.tl.set(lv.targ,{opacity: 0, y: 5})
  lv.targ = '#modal_takeaways_btn'
  lv.tl.set(lv.targ,{opacity: 0, scale: 0.1})

  lv.tl.call(switchOverflowIn)
  lv.targ = '#modal_takeaways'
  lv.tl.to(lv.targ, {duration: 2, opacity:1, ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, scale: 1, ease:'power3.inOut'},'-=1')
  lv.targ = '#modal_takeaways_title'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_takeaways_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_takeaways_btn'
  lv.tl.to(lv.targ, {duration: 3, opacity:1, scale: 1, ease:'elastic.out'} ,'-=0.5')
  lv.tl.timeScale(3)

}

function modal_takeaways_hide(){
  lv.tl = gsap.timeline()
  lv.targ = '#modal_takeaways_btn'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'expo.inOut'})
  lv.targ = '#modal_takeaways_title'
  lv.tl.to(lv.targ, {duration: 1, opacity: 0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_takeaways_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_takeaways_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, scale: 0.95, ease:'power3.inOut'},'-=0.5')
  lv.targ = '#modal_takeaways'
  lv.tl.to(lv.targ, {duration: 2, opacity:0, display: 'none', ease:'power3.inOut'},'-=0.5')
  lv.tl.call(switchOverflowOut)
  lv.tl.call(modal_takeaways_closed)
  lv.tl.timeScale(3)
}

function switchOverflowIn(){
  lv.body_width_offset = getScrollbarCompensation()
  lv.body.style.overflowY = 'hidden'
  lv.body.style.paddingRight = lv.body_width_offset + 'px'
}
function switchOverflowOut(){
  lv.body.style.overflowY = 'scroll'
  lv.body.style.paddingRight = 0
}

function getScrollbarCompensation() {
  lv.start_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'hidden'
  lv.end_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'scroll'
  return lv.end_width - lv.start_width
}

function modal_takeaways_closed(){
  EventBus.$emit('modal_takeaways_closed')
}


lv.dataTask_set = "takeaway_set"
lv.dataEvent_set = "data_takeaway_set_2"
function bookmark_send(ob){
  lv.thisVue.btn_disabled = false
  api.call({
    task: lv.dataTask_set,
    event: lv.dataEvent_set,
    global: false,
    formData: ob
  })
}

function dataLoaded_set(d) {
  lv.thisVue.btn_disabled = true
  if (d.result == "nok") {
    console.warn(d)
    return
  }
  if (d.result == "ok") {
    console.log('takeaway saved')
    //lv.thisVue.hide()
    modal_takeaways_finish_set()
    EventBus.$emit('takeaways_refetch')
  }
}
EventBus.$on(lv.dataEvent_set, (e) => {
  dataLoaded_set(e);
})




lv.dataTask = "takeaway_unset"
lv.dataEvent = "data_takeaway_unset"
function bookmark_remove(ob){
  lv.thisVue.btn_disabled = false
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: ob
  })
}

function dataLoaded(d) {
  lv.thisVue.btn_disabled = true
  if (d.result == "nok") {
    console.warn(d)
    return
  }
  if (d.result == "ok") {
    console.log('takeaway removed')
    //lv.thisVue.hide()
    modal_takeaways_finish()
    EventBus.$emit('takeaways_refetch')
  }
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e);
})

function modal_takeaways_finish(){

  /*
  gsap.set('#modal_takeaways_main',{display: 'none'})
  gsap.set('#modal_takeaways_finish',{display: 'block'})
  */

  lv.targ = '#modal_takeaways_wrap'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#modal_takeaways_wrap'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_takeaways_main'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_takeaways_finish'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#modal_takeaways_wrap'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_takeaways_finish'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_takeaways_main'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_main'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_finish'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

}


function modal_takeaways_finish_set(){

  /*
  gsap.set('#modal_takeaways_main',{display: 'none'})
  gsap.set('#modal_takeaways_finish',{display: 'block'})
  */

  lv.targ = '#modal_takeaways_wrap'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#modal_takeaways_wrap'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_takeaways_main'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_takeaways_finish_set'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#modal_takeaways_wrap'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#modal_takeaways_finish_set'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#modal_takeaways_main'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_main'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_finish_set'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#modal_takeaways_wrap'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

}


</script>

<style lang="scss" scoped>

.maxwlg{
  max-width: 1040px;
}
.maxwsm{
  max-width: 580px;
}
.shadow-custom{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.bg-pattern-1{
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%232f2f2f' fill-opacity='0.5' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.modal_takeaways_info{
  margin-right: 20px;

}
.vspacer{
  width: 100vw;
}
.hspacer{
  height: 90px;
}

.maxwxlg{
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
}
.maxwi{
  width:100%;
  max-width: 440px;
}
.iconremove{
  top:6px;
}

.navpos{
  top:0px;
  left:0px;
  padding: 6px 67px 6px 67px;
}
.padtop{
  padding-top: 66px;
}

@media (max-width: 480px){
.navpos{
    top:55px;
    left:0px;
    padding: 6px 35px 6px 35px;
  }
  .padtop{
    padding-top: 110px;
  }
}


</style>
