<template>
  <div class="">

    <admin @admin_allow='admin_allow' v-if="preview_mode" />

    <div v-if="allowed">

      <div class="vspace_top_page_2" ></div>
      <div class="mode-center">

        <div class="">
          <div class="text-hdr-6">
            Hi {{user_first_name}},
          </div>
          <div class="text-subhdr-6 font-mono mt-5">
            Dit zijn de cursussen waar jij aan deelneemt
          </div>
        </div>

        <div class="mt-16">

          <course_card  
            v-for="item in items" 
            :key="item.slug" 
            :color="item.color_course"
            :title="item.title" 
            :teaser_text="item.teaser_text" 
            :description="item.description" 
            :text="item.text" 
            :id="item.id" 
            :slug="item.slug" 
            :img="item.img" 
            :to="item.to"
            :percentage="item.percentage"
            :time_string="item.time_string"
            :lesson_amount="item.lesson_amount"
          />

        </div>

        <div class="vspace_middle_course"></div>

        <div class="">
          <div class="text-hdr-4">
            Onze andere cursussen
          </div>
          <div class="text-subhdr-6 font-mono mt-5">
            Over een tijdje kan je hier andere cursussen vinden
          </div>
        </div>

      </div>

      <div class="vspace_bottom_course"></div>

    </div>


  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import admin from '@/components/account/admin'

import course_card from '@/components/course_card.vue'

var lv = {}
lv.thisVue = null
lv.dataTask = 'courses_fetch'
lv.apimode = 'normal'

export default {
  name: "courses",
  components:{
    course_card,
    admin
  },
  props: [],
  data() {
    return {
      user_first_name: null,
      items: [],
      preview_mode: false,
      allowed: true
    }
  },
  mounted() {
    lv.thisVue = this

    this.checkPreviewMode()

    this.user_first_name = globals.user_data.first_name

  },
  methods:{
    fetch(){
      lv.dataTask = 'courses_fetch'
      lv.dataEvent = 'data_courses_home'
      api.call({
        task: lv.dataTask,
        global: true,
        mode: lv.apimode,
        event: lv.dataEvent
      })
      EventBus.$on(lv.dataEvent, (e) => {
        lv.dataTask = 'courses_fetch'
        dataLoaded(e)
      })

      function dataLoaded(d){
        if (d.result != 'ok') {
          control_main.console_log('----- data load fail -----')
        }
        if (d.result == 'ok') {
          //control_main.console_log('----- data load success -----')
          lv.thisVue.populate()
        }
      }
    },
    populate(){
      lv.transpose = []
      for (let key in globals.api_data[lv.dataTask].items) {
        lv.item = []
        lv.item.id = globals.api_data[lv.dataTask].items[key].id
        lv.item.title = globals.api_data[lv.dataTask].items[key].title
        lv.item.slug = globals.api_data[lv.dataTask].items[key].slug
        lv.item.teaser_text = globals.api_data[lv.dataTask].items[key].teaser_text
        lv.item.description = globals.api_data[lv.dataTask].items[key].description
        lv.item.text = globals.api_data[lv.dataTask].items[key].text
        //lv.item.img = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].items[key].header_image].medium
        lv.item.img = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].items[key].featured_image].file
        lv.item.to = '/cursussen/' + lv.item.slug
        if(lv.thisVue.preview_mode){
          lv.item.to = '/preview/cursussen/' + lv.item.slug
        }
        lv.item.percentage = globals.api_data[lv.dataTask].items[key].percentage
        lv.item.color_course = globals.api_data[lv.dataTask].items[key].color
        if(!lv.item.color_course){
          lv.item.color_course = '#00d45b'
        }
        lv.item.lesson_amount = globals.api_data[lv.dataTask].items[key].lesson_count

        lv.item.time_string = globals.api_data[lv.dataTask].items[key].hours_to_study_total + ' uur'
        lv.transpose.push(lv.item)
      }
      this.items = lv.transpose
    },
    admin_allow(){
      if(this.preview_mode){
        if(globals.admin_user){
          this.allowed = true
        }else{
          this.allowed = false
        }
      }
    },
    checkPreviewMode(){
      if(this.$route.name === 'PreviewCourses'){
        control_main.console_log('_______ Admin Preview Mode _______')
        this.preview_mode = true
        lv.apimode = 'preview'
        if(globals.api_data.courses_fetch){
          if(globals.api_data.courses_fetch.mode != 'preview'){
            delete globals.api_data.courses_fetch
          }
        }
      }else{
        this.preview_mode = false
        lv.apimode = 'normal'
        if(globals.api_data.courses_fetch){
          if(globals.api_data.courses_fetch.mode == 'preview'){
            delete globals.api_data.courses_fetch
          }
        }
      }
      this.fetch()
    }    
  }
}

</script>
<style  lang="scss">


</style>
