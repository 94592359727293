<template>
  <div class="">
    <div class="rounded-lg padding_lesson_block bg-white">
      <div class="text-micro-1 font-normal uppercase pb-8 mxsm:pt-16">
        {{blockObj.name}}
      </div>
      <div class="wrap_bookmark">
        <div class="flex">
          <div class="txt_bookmark text-micro-3 font-mono flex-shrink-0">
            sla op als takeaway
          </div>
          <div class="ticon_bookmark rounded-full bg-blue-sky ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out" @click="bookmark">
            <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
          </div>
        </div>
      </div>
      <div class="text-hdr-4 pb-4">
        {{blockObj.title}}
      </div>
      <div class="text-subhdr-3 wysiwyg_text bookmarkable" v-html="blockObj.text" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
      </div>
      <div class="a" v-if="blockObj.foldouts">
        <foldouts :obj="blockObj.foldouts" />
      </div>
      <div class="a" v-if="blockObj.attachments">
        <attachments :obj="blockObj.attachments" />
      </div>
    </div>

    <div class="rounded-lg bg-grey-light mt-3 overflow-hidden relative" v-if="featured_video">
      <div class="video_wrap">
        <vue-plyr ref="plyr_youtube" v-if="video_youtube">
          <div class="plyr__video-embed">
            <iframe allowfullscreen allowtransparency allow="autoplay" :src="src_youtube"></iframe>
          </div>
        </vue-plyr>
        <vue-plyr ref="plyr_vimeo" v-if="video_vimeo">
          <div class="plyr__video-embed">
            <iframe allowfullscreen allowtransparency allow="autoplay" :src="src_vimeo"></iframe>
          </div>
        </vue-plyr>
      </div>
    </div>

    <div class="rounded-lg range_padding bg-grey-light mt-3 mb-3" :id="identifier">

      <div class="mxmd:grid mxmd:grid-cols-12 mxmd:gap-10">

        <div class="slider_track_wrap col-span-2">
          <div class="h-2 mxmd:h-full mxmd:w-2 bg-black rounded relative rangeslider_track">
            <!--  
            <div class="absolute posindicator"
            v-for="(item, index) in items"
            :key="item.key"
            :value="item.key"
             :id="'posindicator_' + (index+1)"
            ></div>
            -->
            <div class="pos-center rangeslider_outside" @click="click_outside_slider"></div>
            <div class="absolute cursor-pointer rangeslider_playhead"
              @click.stop
              @mouseenter="playhead_enter"
              @mouseleave="playhead_leave"
            >
              <div class="pos-center w-12 h-12 rounded-full bg-white border-2 border-black"></div>
            </div>
          </div>
        </div>

        <div class="mnmd:flex mnmd:justify-between col-span-10">
          <div class="mnmd:text-centerpy-2 pr-4 pb-8 last:pb-0 last:pr-0 font-bold relative rangeslider_items"
            v-for="(item, index) in items"
            :key="item.key"
            :value="item.key"
          >
            <div class="px-3 py-2 rounded cursor-pointer inline-block" 
              :class="'rangeslider_item_' + (index+1)" 
              @click="click_range_item(index+1)"
              @mouseenter="mouseover(index+1)"
              @mouseleave="mouseleave(index+1)"
              >      
              {{item.label}}
            </div>
          </div>

        </div>      

      </div>

    </div>

    <div class="text-right pt-8">
      <div class="inline-block w100">
        <btn_default text="ga verder" type="wide" :state="next_disabled" @btn-click="saveAnswer"  />
      </div>
    </div>
  </div>
</template>



<script>
import gsap from 'gsap'
import { Draggable } from 'gsap/Draggable.js'
gsap.registerPlugin(Draggable)
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import * as cheet from 'cheet.js'

import btn_default from '@/components/ui/btn_default.vue'
import attachments from '@/components/misc/attachments.vue'
import foldouts from '@/components/misc/foldouts.vue'



var lv = {}
lv.thisVue = null

export default {
  name: 'rangeslider',
  components: {
    btn_default,
    attachments,
    foldouts
  },
  props: ['div_id','type','blockObj'],
  data() {
    return {
      answer: null,
      answer_count: 0,
      correct_count: 0,
      multi: false,
      items:[],
      chosen: null,
      specs: {},
      next_disabled: 'disabled',
      color_highlight: '#36ae95',
      color_chosen: '#36ae70',
      identifier: 'rangeslider_',
      least_diff: 1000,
      rangefeel: 'left',
      range_positions:{
        left:{},
        top:{}
      },
      nr:0,
      video_youtube: false,
      video_vimeo: false,
      src_youtube: null,
      src_vimeo: null,
      featured_video: false
    }
  },
  mounted() {
    lv.thisVue = this

    this.chosen = []
    this.identifier = 'rangeslider_' + this.$props.blockObj.id
    this.fromStart = false

    this.answer_count = 0
    lv.answers = JSON.parse(this.$props.blockObj.block_rangeslider_steps)
    lv.answers.forEach(this.parseAnswers)
    this.count_options = lv.answers.length

    var block_item_done = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done

    this.nr = 0
    while(this.nr < this.count_options){
      this.nr++
      this.range_positions.left[this.nr] = 0
      this.range_positions.top[this.nr] = 0
    }
    EventBus.$on('lesson_part_reveal', (e) => {
      if(e === this.$props.blockObj.id){
        this.calc_range_pos()
        if(block_item_done){
          this.fromStart = true
          this.haveAnswer()
          this.resize_win()
        }
      }
    })
    
    this.$nextTick(function () {
      if(block_item_done){
        this.haveAnswer()
      }
      this.resize_win()
      this.dragify()
    })

    lv.host_string = window.location.protocol + "//" + window.location.host
    lv.youtube_embed_vars = "?origin=" + lv.host_string + "&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"

    if(this.$props.blockObj.block_rangeslider_youtube){
      this.src_youtube = "https://www.youtube.com/embed/" + this.$props.blockObj.block_rangeslider_youtube + lv.youtube_embed_vars
      this.video_youtube = true
      this.featured_video = true
    } else if(this.$props.blockObj.block_rangeslider_vimeo){
      this.src_vimeo = "https://player.vimeo.com/video/" + this.$props.blockObj.block_rangeslider_vimeo + "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
      this.video_vimeo = true
      this.featured_video = true
    }

    EventBus.$on('lesson_part_reveal', (e) => {
      if(this.src_youtube && this.$refs.plyr_youtube){
        this.$refs.plyr_youtube.player.pause()
        control_main.console_log('pause video youtube')
      }
      if(this.src_vimeo && this.$refs.plyr_vimeo){
        this.$refs.plyr_vimeo.player.pause()
        control_main.console_log('pause video vimeo')
      }
    })

  },
  methods: {
    haveAnswer(){
      this.specs = JSON.parse(globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].specs)
      if(this.specs.answer_numbers){
        this.nr = 0
        while(this.nr < this.specs.answer_numbers.length){
          this.setAnswer(this.specs.answer_numbers[this.nr])
          this.nr++
        }
      }
    },
    calc_range_pos_x(){

      //gsap.set('.posindicator',{width:1, height:15, top: 0})

      lv.rect_parent = document.getElementById(this.identifier).getElementsByClassName('rangeslider_items')[0].getBoundingClientRect()
      this.nr = 0
      while(this.nr < this.count_options){
        this.nr++
        lv.rect = document.getElementById(this.identifier).getElementsByClassName('rangeslider_item_' + this.nr)[0].getBoundingClientRect()
        lv.offsetleft = lv.rect.left - lv.rect_parent.left
        lv.posleft = lv.offsetleft + (lv.rect.width * 0.5)
        this.range_positions.left[this.nr] = lv.posleft
        //gsap.set('#posindicator_' + this.nr,{left: lv.posleft})
      }
    },
    calc_range_pos_y(){

      //gsap.set('.posindicator',{width:15, height:1, left: 0})

      lv.rect_parent = document.getElementById(this.identifier).getElementsByClassName('rangeslider_items')[0].getBoundingClientRect()
      this.nr = 0
      while(this.nr < this.count_options){
        this.nr++
        lv.rect = document.getElementById(this.identifier).getElementsByClassName('rangeslider_item_' + this.nr)[0].getBoundingClientRect()
        lv.offsettop = lv.rect.top - lv.rect_parent.top
        lv.postop = lv.offsettop + (lv.rect.height * 0.5)
        this.range_positions.top[this.nr] = lv.postop
        //gsap.set('#posindicator_' + this.nr,{top: lv.postop})
      }

    },
    calc_range_pos(){
      if(window.innerWidth <= 720){
        this.calc_range_pos_y()
      }else{
        this.calc_range_pos_x()
      }
    },
    resize_win(){
      if(window.innerWidth <= 720){
        this.rangefeel = 'top'

        gsap.set('#' + this.identifier + ' .rangeslider_outside', {width: '400%', height: '100%'})
      }else{
        this.rangefeel = 'left'

        gsap.set('#' + this.identifier + ' .rangeslider_outside', {width: '100%', height: '400%'})
      }
      this.calc_range_pos()
      lv.dst = 1
      if(this.chosen.length > 0){
        lv.dst = this.chosen[0]
      }
      this.range_playhead(this.chosen[0], 0.01)
      this.dragify()
    },
    click_range_item(i){
      this.setAnswer(i)
    },
    click_outside_slider(e){
      lv.rect = e.target.getBoundingClientRect()
      lv.posleft = e.clientX - lv.rect.left
      lv.postop = e.clientY - lv.rect.top
      this.calc_drag_dest(lv['pos' + this.rangefeel])
    },
    playhead_enter(){
      lv.targ = '#' + this.identifier + ' .rangeslider_playhead'
      gsap.to(lv.targ,{duration:0.3, scale: 1.15, ease:'power3.inOut'})
    },
    playhead_leave(){
      lv.targ = '#' + this.identifier + ' .rangeslider_playhead'
      gsap.to(lv.targ,{duration:0.3, scale: 1, ease:'power3.inOut'})
    },
    range_playhead(who, dur){ 
      if(window.innerWidth <= 720){
        lv.targ = '#' + this.identifier + ' .rangeslider_playhead'
        gsap.to(lv.targ,{duration:dur, left: 4, top: this.range_positions.top[who], ease:'power3.inOut'})
      }else{
        lv.targ = '#' + this.identifier + ' .rangeslider_playhead'
        gsap.to(lv.targ,{duration:dur, top:3, left: this.range_positions.left[who],  ease:'power3.inOut'})
      }
    },
    dragify(){
      var draggable = new Draggable.create('#' + this.identifier + ' .rangeslider_playhead', {
          type:this.rangefeel,
          bounds: '#' + this.identifier + ' .rangeslider_track',
          zIndexBoost: false,
          onDragEnd:() => {
            this.calc_drag_dest(gsap.getProperty('#' + this.identifier + ' .rangeslider_playhead',this.rangefeel))
          }
      })
    },
    calc_drag_dest(poscur){
      this.least_diff = 1000
      lv.targ_answer = 0
      lv.targ_index = 0
      for (let key in this.range_positions[this.rangefeel]) {
        lv.difference = this.calc_difference(poscur, this.range_positions[this.rangefeel][key] )
        if(lv.difference < this.least_diff){
          this.least_diff = lv.difference
          lv.targ_answer = parseInt(key)
          lv.targ_index = lv.cnt
        }
      }
      lv.targ = '#' + this.identifier + ' .rangeslider_playhead'
      if(window.innerWidth <= 720){
        gsap.to(lv.targ,{duration:0.2, left:4, top: this.range_positions[this.rangefeel][lv.targ_answer], ease:'power3.inOut'})
      }else{
        gsap.to(lv.targ,{duration:0.2, top:3, left: this.range_positions[this.rangefeel][lv.targ_answer], ease:'power3.inOut'})
      }
      this.setAnswer(lv.targ_answer)
    },
    calc_difference(a,b){
      return Math.abs(a - b)
    },
    parseAnswers(value) {
      this.answer_count++
      lv.item_specs = {
        key: this.answer_count,
        label: value['step']
      }
      this.items.push(lv.item_specs)
    },
    mouseover(who){
      this.choice_set(who)
    },
    mouseleave(who){
      this.choice_unset(who)
    },
    choice_set(who){
      if(this.chosen.includes(who)){
        return
      }
      lv.targ = '#' + this.identifier + ' .rangeslider_item_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': this.color_highlight,  ease:'power3.inOut'})
    },
    choice_unset(who){
      if(this.chosen.includes(who)){
        return
      }
      lv.targ = '#' + this.identifier + ' .rangeslider_item_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': 'transparent',  ease:'power3.inOut'})
    },
    choice_settle(who){
      if(this.chosen.includes(who)){
        this.removeChosen(this.chosen, who)
        this.choice_unset(who)
        return
      }
      this.chosen.push(who)
      lv.targ = '#' + this.identifier + ' .rangeslider_item_' + who
      gsap.to(lv.targ,{duration:0.4, 'background-color': this.color_chosen,  ease:'power3.inOut'})
      gsap.to(lv.targ,{duration:0.2,  scale: 1.1, ease:'power4.inOut'})
      gsap.to(lv.targ,{delay:0.15, duration:0.2,  scale: 1, ease:'power4.inOut'})
      lv.dur = 0.5
      if(this.fromStart){
        lv.dur = 0.01
        this.fromStart = false
      }
      this.range_playhead(who, lv.dur)
    },
    choice_unset_all(){
      this.nr = 0
      while(this.nr < this.answer_count){
        this.nr++
        this.removeChosen(this.chosen, this.nr)
        this.choice_unset(this.nr)
      }
    },
    setAnswer(who){
      this.next_disabled = ''
      this.choice_unset_all()
      this.choice_set(who)
      this.choice_settle(who)
    },
    saveAnswer() {

      this.answer_specs = {}
      this.answer_specs['answer_numbers'] = this.chosen
      this.answer_specs['label'] = this.items[this.chosen - 1].label

      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done = true
      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].specs = JSON.stringify(this.answer_specs)
      
      lv.signal = {
        index: this.blockObj.index_id,
        lesson: this.blockObj.course_data.lesson_slug
      }
      EventBus.$emit('lesson_block_done', lv.signal)

      lv.answer_data = {
        type: this.blockObj.type,
        block_id: this.blockObj.id,
        lesson_id: this.blockObj.course_data.lesson_id,
        course_id: this.blockObj.course_data.course_id,
        lesson_slug: this.blockObj.course_data.lesson_slug,
        course_slug: this.blockObj.course_data.course_slug,
        user_id: globals.user_data.id,
        action: 'done',
        answer_specs: this.answer_specs
      }
      api.call({
        task: 'answer_save',
        event: 'data_answer_save',
        answerData: lv.answer_data,
        slug: this.blockObj.course_data.lesson_slug,
        global: false
      })

      this.next()
      
    },
    next() {
      EventBus.$emit('lesson_nav', this.blockObj.index_id + 1)
    },
    removeChosen(arr, value) {
      var index = arr.indexOf(value)
      if (index > -1) {
        arr.splice(index, 1)
      }
      return arr
    },
    bookmark(){   
      lv.modal_data = {
        type: 'bookmark',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      //EventBus.$emit('bookmark_selection')
    }   
  },
  created() {
    window.addEventListener('resize', this.resize_win)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize_win)
  }
}

</script>

<style scoped lang="scss">

  .multiple_minh{
    min-height: 135px;
  }
  .multiple_padding{
    padding:10px 10px 10px 10px;
  }
  .multiple_radio{
    width:50px;
    height: 50px;
  }

  .range_padding{
    padding: 50px 33px 38px 33px;
  }
  .slider_track{
    background: #000;
    height: 10px;
    border-radius: 5px;
  }
  .slider_track_wrap{
    padding: 70px 0px 40px 0px;
  }
  @media (max-width: 720px){
    .slider_track_wrap{
      padding: 0px 0px 0px 25px;
    }
  }

  .posindicator{
    background: red;
    width:1px;
    height: 15px;
    top:0px;
    left:0px;
    opacity: 0;
  }

.rangeslider_playhead{
  top:3px;
  left:24px;
}

</style>


