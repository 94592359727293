<template>
  <div class="relative pb-8">
    <div class="rounded-lg overflow-hidden">
      <div class="grid grid-cols-12 gap-0 mxmd:grid-cols-1">
        <div class="col-span-8 bg-white lessoncard_pad lesson_card flex flex-col">
          <div class="lesson_textpb">
            <div class="text-hdr-2">
              {{title}}
            </div>
            <div class="text-subhdr-3 lessoncard_text pt-2 pl-1" v-html="teaser_text">
            </div>
          </div>
          <div class="mt-auto">
            <div class=" flex justify-between mb-4 mxxsm:block">
              <div class="">
                <div class="inline-block lesson_marginright">
                  <div class="inline-block align-middle">
                    <div class="lesson_iconwrap">
                      <!--  
                      <div class="rounded-full w-full h-full bg-gray-800 opacity-10"></div>
                      -->

                      <img class="icon_80_2" src="@/assets/img/icons_graphic/icon_study-time.png" />

                    </div>
                  </div>
                  <div class="inline-block align-middle">
                    <div class="text-mini-1 font-mono lesson_microtext">
                      duur les: {{time_string}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-auto text-right">
                <div class="lessen_btn_pad">
                  <a :href="to" class="text-subhdr-3 block lesson_btn rounded-md px-2 pt-1 pb-2 hover:bg-black hover:text-white transition duration-200 ease-in-out" >
                    <div class="inline-block align-middle mr-2">                   
                      {{btn_text}}
                    </div>
                    <div class="inline-block align-middle">                    
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <progress_bar progress_text="voortgang" :percentage="percentage" bg="gray" />
          </div>
        </div>
        <div class="col-span-4 lesson_card bg-cover bg-center lesson_height_img bg-gray-700" v-bind:style="{ 'background-image': 'url(' + img + ')' }">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import progress_bar from '@/components/misc/progress_bar.vue'

export default {
  name: "lesson_card",
  components: {
    progress_bar
  },
  props: ['id','slug','title', 'teaser_text','description','text', 'img','to','percentage','time_string'],
  data() {
    return {
      btn_text_done: 'bekijk',
      btn_text_resume: 'ga verder',
      btn_text_start: 'start',
      btn_text: ''
    }
  },
  mounted() {
    if(this.$props.percentage === 100){
      this.btn_text = this.btn_text_done
    }else if(this.$props.percentage > 0){
      this.btn_text = this.btn_text_resume
    }else{
      this.btn_text = this.btn_text_start
    }
    
  }
}
</script>

<style scoped lang="scss">

.lesson_card{
  min-height: 350px;
}
.lessoncard_pad{
  padding: 27px 10px 33px 33px;
}
.lessoncard_text{
  font-size: 16px;
  max-height: 100px;
  overflow: hidden;
}
.lesson_marginright  {
  margin-right: 15px;
}
.lesson_iconwrap{
  width: 70px;
  height: 70px;
  margin-right: 15px;

}
.lesson_microtext{
  font-size: 14px;
  letter-spacing: 1px;
}
.lesson_textpb{
  padding-bottom: 36px;
}

.lesson_btn{
  font-weight: 700;
}
.lessen_btn_pad{
  padding-top: 22px;
}
.lesson_height_img{
  min-height: 350px;;
}

@media (max-width: 720px){
  .lesson_card{
    min-height:290px;
  }
  .lesson_height_img{
    height:290px;
    min-height: auto;
  }
  .lesson_textpb{
    padding-bottom: 15px;
  }
}

</style>
