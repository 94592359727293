<template>
  <div>

    <div v-if="!allowed">
      <div class="vspace_top_page"></div>
      <div class="mode-center">
        <div class="rounded-lg border bg-white border-gray-500 p-10 text-subhdr-1 flex items-center">
          <div class="mr-6 text-gray-500">
            <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
          </div>
          <div>
            Geen toegang
          </div>
        </div>
        <div class="vspace_middle_page_4"></div>
      </div>
    </div>

    <div v-if="allowed">
      <div class="fixed bottom-0 left-0 p-3 z-50 select-none pointer-events-none">
        <div class="rounded p-2 bg-purple-600 text-white shadow text-micro-2 flex items-center">
          <div class="mr-2">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
          </div>
          <div>
            preview mode
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import * as cheet from 'cheet.js'

var lv = {}
lv.thisVue = null

export default {
  name: 'Preview',
  components: {
  },
  data() {
    return {
      allowed: false
    }
  },
  mounted() {

    lv.thisVue = this

    this.allow_admin()

    if(process.env.NODE_ENV === 'production'){
      api.getAdminUser()
    }
    EventBus.$on('fetched_admin_user', (e) => {
      dataLoaded(e)
    })

    cheet('a d m i n d e v', function () {
      if(process.env.NODE_ENV !== 'production'){
        globals.admin_user = {
          first_name: 'Development',
          last_name: 'Admin'
        }
        EventBus.$emit('fetched_admin_user', {statusText: 'OK'})
      }
    })

    function dataLoaded(d) {
      lv.thisVue.allow_admin()
    }

  },
  methods:{
    allow_admin(){
      if(globals.admin_user){
        this.allowed = true
      }else{
        this.allowed = false
      }
      this.$emit('admin_allow')
    }
  }
}
</script>

<style scoped>


</style>