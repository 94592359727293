<template>
  <div class="">
    <div class="rounded-lg padding_lesson_block bg-white">
      <div class="text-micro-1 font-normal uppercase pb-8 mxsm:pt-16">
        {{blockObj.name}}
      </div>
      <div class="wrap_bookmark">
        <div class="flex">
          <div class="txt_bookmark text-micro-3 font-mono flex-shrink-0">
            sla op als takeaway
          </div>
          <div class="ticon_bookmark rounded-full bg-blue-sky ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out" @click="bookmark">
            <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
          </div>
        </div>
      </div>
      <div class="text-hdr-4 pb-4">
        {{blockObj.title}}
      </div>
      <div class="text-subhdr-3 wysiwyg_text bookmarkable" v-html="blockObj.text" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
      </div>
      <div class="a" v-if="blockObj.foldouts">
        <foldouts :obj="blockObj.foldouts" />
      </div>
      <div class="a" v-if="blockObj.attachments">
        <attachments :obj="blockObj.attachments" />
      </div>
    </div>
    <div class="rounded-lg bg-grey-light mt-3 overflow-hidden relative">

      <div class="video_wrap">

        <vue-plyr ref="plyr_youtube" v-if="video_youtube">
          <div class="plyr__video-embed">
            <iframe
              :src="src_youtube"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr>

        <vue-plyr ref="plyr_vimeo" v-if="video_vimeo">
          <div class="plyr__video-embed">
            <iframe
              :src="src_vimeo"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr>

        <!-- data-poster="http://localhost/uploads/learning-platform/originals/1016-800x600.jpg" -->
        <vue-plyr ref="plyr_embed" :options="options" v-if="video_file">
          <video
            controls
            crossorigin
            playsinline
          >
            <source
              size="720"
              :src="src_file"
              type="video/mp4"
            />
          </video>
        </vue-plyr>

      </div>
      
    </div>
    <div class="padding_caption text-micro-2 italic" v-html="blockObj.block_video_caption">
    </div>
    <div class="flex items-center mxmd:block">
      <div class="flex items-center relative mxmd:w-full">
        <div class="rounded bg-white block_check mr-5">
          <div class="opacity-0 radiocheck block_check relative">                
            <svg class="w-10 h-10 pos-center" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          </div>
        </div>
        <div class="text-body-1">
          Ik heb dit gezien
        </div>
        <div class="pos-g cursor-pointer" @click="setDone"></div>
      </div>
      <div class="ml-auto btn_minw mxmd:text-right mxmd:mt-6">     
        <btn_default text="ga verder" type="wide" :state="next_disabled" @btn-click="saveAnswer"  />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import * as cheet from 'cheet.js'

import btn_default from '@/components/ui/btn_default.vue'
import attachments from '@/components/misc/attachments.vue'
import foldouts from '@/components/misc/foldouts.vue'


var lv = {}
lv.thisVue = null

export default {
  name: 'blockvideo',
  components: {
    btn_default,
    attachments,
    foldouts
  },
  props: ['div_id','type','blockObj'],
  data() {
    return {
      answer: null,
      temptext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt libero ex, quis efficitur sapien ullamcorper eu. Duis fermentum pharetra eros, sed auctor ligula semper sit amet. In hac habitasse platea dictumst. ',
      next_disabled: 'disabled',
      video_youtube: false,
      video_vimeo: false,
      video_file: false,
      src_youtube: null,
      src_vimeo: null,
      src_file: null,
      options:{},
      component_alive: false
   }
  },
  mounted() {
    lv.thisVue = this

    var block_item_done = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done

    this.$nextTick(function () {
      lv.targ = '#' + this.div_id + ' .radiocheck'
      gsap.set(lv.targ,{scale:0})
      if(block_item_done){
        this.setDone()
      }
    })


    lv.host_string = window.location.protocol + "//" + window.location.host
    lv.youtube_embed_vars = "?origin=" + lv.host_string + "&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"

    if(this.$props.blockObj.block_video_youtube){
      this.src_youtube = "https://www.youtube.com/embed/" + this.$props.blockObj.block_video_youtube + lv.youtube_embed_vars
      this.video_youtube = true
    } else if(this.$props.blockObj.block_video_vimeo){
      this.src_vimeo = "https://player.vimeo.com/video/" + this.$props.blockObj.block_video_vimeo + "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
      this.video_vimeo = true
    } else if(this.$props.blockObj.file){
      this.src_file = this.$props.blockObj.file
      this.video_file = true
    }

    EventBus.$on('lesson_part_reveal', (e) => {
      if(this.src_youtube && this.$refs.plyr_youtube){
        this.$refs.plyr_youtube.player.pause()
        control_main.console_log('pause video youtube')
      }
      if(this.src_vimeo && this.$refs.plyr_vimeo){
        this.$refs.plyr_vimeo.player.pause()
        control_main.console_log('pause video vimeo')
      }
      if(this.src_file && this.$refs.plyr_embed){
        this.$refs.plyr_embed.player.pause()
        control_main.console_log('pause video file')
      }
    })



  },
  methods: {
    setDone(){
      this.next_disabled = ''
      lv.targ = '#' + this.div_id + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3,  scale: 1, opacity: 1, ease:'power3.inOut'})
    },
    saveAnswer() {

      this.setDone()

      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done = true
      
      lv.signal = {
        index: this.blockObj.index_id,
        lesson: this.blockObj.course_data.lesson_slug
      }
      EventBus.$emit('lesson_block_done', lv.signal)

      lv.answer_data = {
        type: this.blockObj.type,
        block_id: this.blockObj.id,
        lesson_id: this.blockObj.course_data.lesson_id,
        course_id: this.blockObj.course_data.course_id,
        lesson_slug: this.blockObj.course_data.lesson_slug,
        course_slug: this.blockObj.course_data.course_slug,
        user_id: globals.user_data.id,
        action: 'done',
        answer_specs: {}
      }
      api.call({
        task: 'answer_save',
        event: 'data_answer_save',
        answerData: lv.answer_data,
        slug: this.blockObj.course_data.lesson_slug,
        global: false
      })

      this.next()

    },
    next() {
      EventBus.$emit('lesson_nav', this.blockObj.index_id + 1)
    },
    bookmark(){   
      lv.modal_data = {
        type: 'bookmark',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      //EventBus.$emit('bookmark_selection')
    }   
  }
}
</script>

<style scoped lang="scss">


</style>
