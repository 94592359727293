<template>

  <div>
    <div class="rounded-lg relative bg-grey-med transition duration-300 ease-in-out"
    v-bind:class="{ 'bg-pale-green' : active , 'bg-pale-green' : hovered }"
    >
      <div class="faq-top relative flex items-center">
        <div class="text-hdr-5 text-shrink faq-hdr">
          {{obj.title}}
        </div>
        <div class="faq-icon-arrow show_me" :id="arrow_id" >
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
        </div>
        <div class="pos-g cursor-pointer"
        @click="faq_toggle"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        >
        </div>
      </div>
      <div class="text-subhdr-3 faq-content" style="display:none;" :id="content_id">
        <div class="faq-body" v-html="obj.text">
        </div>
      </div>
    </div>
    <div class="pb-8"></div>
  </div>

</template>

<script>
import gsap from 'gsap'

var lv = {}
lv.thisVue = null

export default {
  name: 'faq_node',
  props: ['obj'],
  data() {
    return {
      active: false,
      hovered: false,
      arrow_id: null,
      content_id: null
    }
  },
  mounted() {
    lv.thisVue = this
    this.arrow_id = 'arrow_' + this.$props.obj.id
    this.content_id = 'body_' + this.$props.obj.id
  },
  methods: {
    faq_toggle(){
      lv.arrow_id = '#' + this.arrow_id
      lv.content_id = '#' + this.content_id

      if(this.active){
        this.active = false
        this.hovered = false

        gsap.to(lv.content_id,{duration:0.45, height: 0, display: 'none', ease:'power3.inOut'})
        gsap.to(lv.arrow_id,{duration:0.45, rotate: '0deg', ease:'power3.inOut'})
      }else{
        this.active = true
        this.hovered = true

        gsap.set(lv.content_id,{height: 0, display: 'block'})
        gsap.to(lv.content_id,{duration:0.45, height: 'auto', ease:'power3.inOut'})
        gsap.to(lv.arrow_id,{duration:0.45, rotate: '180deg', ease:'power3.inOut'})
      }
    },
    mouseOver(){
      this.hovered = true
    },
    mouseOut(){
      if(!this.active){
        this.hovered = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>

  .faq-top{
    min-height: 90px;
  }
  .faq-hdr{
    padding: 10px 30px;
  }
  .faq_icon_div{
    width:55px;
    height:55px;
  }
  .faq-icon-question{
    position: absolute;
    top:20px;
    left:19px;
  }
  .faq-icon-arrow{
    position: absolute;
    top: 30px;
    right: 15px;
  }
  .faq-body{
    padding: 0px 30px 40px 30px;
  }


  .faq-content{
    overflow: hidden;
  }

  .show_me{
    display: block;
  }

  .hide_me{
    display: none;
  }

  @media (max-width: 720px){
    .text-shrink{
        font-size: 3.4vw;
    }
  }


</style>
