<template>

  <div class="relative">

    <div class="absolute top-0 left-0 w-full h-full overflow-hidden" ref="hdr_img_slot">
      <div class="pos-g bg-cover bg-center" v-bind:style="{ 'background-image': 'url(' + lesson_homeObj.img + ')' }"></div>
      <div class="pos-g bg-black course_opac"></div>
    </div>

    <div class="absolute top-0 left-0 w-full h-full overflow-hidden bg-gray-800" ref="hdr_img_wrap" id="hdr_lesson_move">
      <div class="pos-g" id="lesson_img_scroll">
        <div class="w-full pos-center">
          <img :src="lesson_homeObj.img" class="w-full" ref="hdr_image" /> 
        </div>
      </div>
      <div class="pos-g bg-black course_opac"></div>
    </div>

    <div class="relative mode-center">
      <div class="lesson_hdr_pad">
        <div class="text-body-1 text-white breadcrumb">
          <router-link :to="link_course" exact class="inline-block relative hover:text-blue-sky">{{lesson_homeObj.course_title}}</router-link>
           > {{ lesson_homeObj.title }}
        </div>
        <div class="text-white">
          <div class="text-hdr-4 pt-3 ">
            {{ lesson_homeObj.title }}
          </div>
          <div class="text-subhdr-1 font-mono pt-2">
            {{ lesson_homeObj.description }}
          </div>
          <div class="text-subhdr-3 text-white lesson_txt_pad lesson_text_minh" v-html="lesson_homeObj.text">
          </div>

          <div class="pt-4 pb-12 grid grid-cols-2 gap-4 mxmd:grid-cols-1">
            <div class="">
              <div class="inline-block align-top">
                <div class="inline-block align-top">
                  <div class="course_iconwrap">
                    <!--  
                    <div class="rounded-full w-full h-full bg-gray-100 opacity-10"></div>
                    -->

                    <img class="icon_100_2" src="@/assets/img/icons_graphic/100x100/icon_study-time.png" />

                  </div>
                </div>
                <div class="inline-block align-top pt-9">
                  <div class="text-body-1">
                    duur les
                  </div>
                  <div class="text-mini-1 font-mono">
                    {{lesson_homeObj.time_string}}
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right flex w100">
              <div class="mt-auto ml-auto w100 pt-6 pb-4">
                <btn_default text="ga verder" type="wide" @btn-click="lesson_start"   />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>


</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null

export default {
  name: "lesson_home",
  components: {
    btn_default
  },
  props: ['lesson_homeObj'],
  data() {
    return {
      link_course: '#'
    }
  },
  mounted() {
    lv.thisVue = this

    lv.route_full = this.$route.fullPath
    lv.route_parts = lv.route_full.split('/')
    lv.route_course = '/' + lv.route_parts[1] + '/' + lv.route_parts[2]
    if(this.lesson_homeObj.preview_mode){
      lv.route_course = '/' + lv.route_parts[1] + '/' + lv.route_parts[2] + '/' + lv.route_parts[3]
    }
    this.link_course = lv.route_course

    this.$nextTick(function () {
      setTimeout(function(){
        parallax_hdr_init()
      },100)
    })

    
  },
  methods: {
    lesson_start(){
      EventBus.$emit('lesson_nav', 'start')
    }
  },
  destroyed(){
    gsap.ticker.remove(parallax_hdr)
    window.removeEventListener('scroll', parallax_hdr)
    window.removeEventListener('resize', parallax_hdr)
    control_main.console_log('destroyed lesson home')
  },
}


lv.wrapper_height = null
lv.img_height =  null
lv.remaining_height = null

function parallax_hdr(){
  lv.window_scroll = window.pageYOffset
  lv.wrapper_height = lv.thisVue.$refs.hdr_img_wrap.getBoundingClientRect().height
  lv.img_height = lv.thisVue.$refs.hdr_image.getBoundingClientRect().height
  //lv.wrapper_height = document.getElementById('hdr_lesson_move').getBoundingClientRect().height
  //lv.img_height = document.getElementById('course_img').getBoundingClientRect().height
  if(lv.img_height <= (lv.wrapper_height + 50)){
    gsap.set('#hdr_lesson_move', {visibility: 'hidden'})
    return
  }else{
    gsap.set('#hdr_lesson_move', {visibility: 'visible'})
  }
  if(lv.window_scroll > lv.wrapper_height + 10){
    return
  }
  if(lv.last_scroll_y == lv.window_scroll){
    return
  }
  lv.last_scroll_y = lv.window_scroll
  lv.remaining_height = ((lv.img_height - lv.wrapper_height) * 0.5)
  lv.scrollpos = ((lv.window_scroll  / lv.wrapper_height) * lv.remaining_height)
  lv.scrolldest = lv.scrollpos
  gsap.set('#lesson_img_scroll',{y: lv.scrolldest + 'px'})
  //control_main.console_log('para relaxing')
}

function parallax_hdr_init(){
  //window.addEventListener('scroll', parallax_hdr)
  //window.addEventListener('resize', parallax_hdr)
  gsap.ticker.add(parallax_hdr)
  parallax_hdr()
}



</script>

<style lang="scss">
  .lesson_hdr_pad{
    padding: 137px 0px 60px 0px;
  }
  .breadcrumb{
    font-weight: normal;
    letter-spacing: 0.4px;
  }
  .lesson_txt_pad{
    padding: 20px 0px 10px 0px;
  }

  .lesson_text_minh{
    min-height: 260px;
  }
</style>
