<template>

      <div class="mode-center-big">
        <div class="mode-center">
          <div class="p-6"></div>

          <div class="relative text-right">
            <div class="btn_add">
              <div class="rounded-full bg-black text-white relative wrap_icon_add transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer" @click="takeaway_new_note">
                <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 mxxl:grid-cols-1">
            <div class="col-span-2 mxxl:col-span-1">
              <div class="relative">
                <input type="text" v-model="search_string" placeholder="zoek op cursus, les, onderdeel of jouw reden"
                class="w-full py-3 px-5 border border-gray-300 rounded-md">
                <div class="absolute top-0 right-0 pointer-events-none text-gray-400 py-2 px-3 mxxsm:hidden">
                  <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
              </div>    
            </div>
            <div class="col-span-1">
              <div class="flex item">
                <div class="flex-grow">
                  <div class="relative w-select">
                    <select class="appearance-none py-3 px-5 pr-12 border border-gray-300 rounded-md mxxxxl:w-full" v-model="sort_ordering" @change="select_change">
                        <option value="" disabled selected>sorteer op</option>
                        <option value="first">datum: eerste - laatste</option>
                        <option value="last">datum: laatste - eerste</option>
                    </select>
                    <div class="absolute top-0 right-0 pointer-events-none text-gray-400 py-2 px-3">
                      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center pt-4 mxmd:block">
            <div class="flex flex-shrink-0 mr-6 mb-6 pt-1">
              <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>
              <div class="a">
                filter op:
              </div>
            </div>

            <div class="flex mxmd:block">
              <div class="flex flex-shrink-0 relative mr-6 mb-4">
                <div class="relative rounded border border-gray-400 mr-2 filtercheck">
                  <div class="pos-g" :class="[filter_active_bookmark ? 'block' : 'hidden']">
                    <div class="pos-center">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </div>
                  </div>
                </div>
                <div class="mr-1 font-bold">onderdeel</div>
                <div class="filtericon">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
                </div>
                <div class="pos-g cursor-pointer" @click="filter_cards('bookmark')"></div>
              </div>

              <div class="flex flex-shrink-0 relative mr-6 mb-4">
                <div class="relative rounded border border-gray-400 mr-2 filtercheck">
                  <div class="pos-g" :class="[filter_active_selection ? 'block' : 'hidden']">
                    <div class="pos-center">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </div>
                  </div>
                </div>
                <div class="mr-1 font-bold">gemarkeerde tekst</div>
                <div class="filtericon">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </div>
                <div class="pos-g cursor-pointer" @click="filter_cards('selection')"></div>
              </div>

              <div class="flex flex-shrink-0 relative mr-6 mb-4">
                <div class="relative rounded border border-gray-400 mr-2 filtercheck">
                  <div class="pos-g" :class="[filter_active_note ? 'block' : 'hidden']">
                    <div class="pos-center">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </div>
                  </div>
                </div>
                <div class="mr-1 font-bold">notities</div>
                <div class="filtericon">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
                </div>
                <div class="pos-g cursor-pointer" @click="filter_cards('note')"></div>
              </div>
            </div>

          </div>

          <div class="p-2"></div>

          <div class="grid grid-cols-3 gap-6 mxxl:grid-cols-2 mxsm:grid-cols-1" v-show="have_data == 'yes'">
            <takeaway_card v-for="(item, index) in data_filter" :key="index" :obj='item' @btn-click="takeaway_click('item_' + item.index, index)" />
          </div>

          <div class="text-center text-hdr-1 maxw_tw_2 m-auto pt-16" v-show="have_data == 'no' || !have_filtered_data">
            Geen takeaways gevonden
          </div>

        </div>
        <div class="p-16"></div>

        <modal_takeaways />

      </div>    

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import gsap from 'gsap'

import takeaway_card from '@/components/takeaway_card.vue'
import modal_takeaways from '@/components/ui/modal_takeaways.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'Takeaways',
  components: {
    takeaway_card,
    modal_takeaways
  },
  data() {
    return {
      data:{},
      have_data: null,
      have_filtered_data: true,
      search_string: null,
      sort_order: 'first',
      sort_ordering: '',
      filter_active_bookmark: false,
      filter_active_selection: false,
      filter_active_note: false,
      type_filters: []
    }
  },
  mounted() {

    this.data = {}

    lv.dataTask = "takeaways_fetch"
    lv.dataEvent = "data_takeaways_detail"

    EventBus.$on('takeaways_refetch', (e) => {
      api.call({
        task: lv.dataTask,
        //global: true,
        event: lv.dataEvent
      })
    })

    EventBus.$on(lv.dataEvent, (e) => {
      if(e.data.data.takeaways.item_1){
        this.have_data = 'yes'
        globals.api_data.takeaways = e.data.data.takeaways

        lv.array_data = []
        for (const prop in e.data.data.takeaways) {
          lv.array_data.push(e.data.data.takeaways[prop])
        }
        console.log(lv.array_data)

        this.data = lv.array_data
        globals.api_data.takeaways_filtered = this.data_filter

      }else{
        this.have_data = 'no'
      }
    })

    EventBus.$emit('takeaways_refetch')

  },
  methods: {
    takeaway_click(i,a){
      console.log(i)
      console.log(a)
      //array_index
      EventBus.$emit('modal_takeaways_open', i, a)
    },
    takeaway_new_note(){
      EventBus.$emit('modal_takeaways_open','new')
    },
    select_change(){
      if(this.sort_ordering == this.sort_order){
        return
      }
      this.sort_order = this.sort_ordering
      this.data_filter.reverse()
      globals.api_data.takeaways_filtered = this.data_filter
    },
    filter_cards(what){
      console.log(what)

      if(this['filter_active_' + what]){
        this['filter_active_' + what] = false

        lv.chk = this.type_filters.indexOf(what)
        if (lv.chk > -1) {
          this.type_filters.splice(lv.chk, 1);
        }
      }else{
        this['filter_active_' + what] = true

        this.type_filters.push(what)
      }

    }
  },
  computed: {
    data_filter() {
        let temp_data = []

        if(this.data.length > 0){
          temp_data = this.data
        }
        
        if (this.search_string != '' && this.search_string) {
          temp_data = temp_data.filter((item) => {
            lv.flat = Object.values(item.takeaway_data).join()
            return lv.flat
              .toUpperCase()
              .includes(this.search_string.toUpperCase())
          })
        }
        
        if(this.type_filters.length > 0){
          temp_data = temp_data.filter((item) => { 
            if(this.type_filters.indexOf(item.type) > -1){
              return item
            }
          })
        }
        
        globals.api_data.takeaways_filtered = temp_data

        if(temp_data.length < 1){
          console.log('no results')
        }
          
        return temp_data
    }
  },
  watch: {
    data_filter: {
      deep: true,
      handler: function(){
        if(globals.api_data.takeaways_filtered.length < 1){
          this.have_filtered_data = false
        }else{
          this.have_filtered_data = true
        }
      }
    }
  }
}
</script>

<style scoped>

.maxw_tw_2{
  max-width: 814px;
}

.wrap_icon_add{
  width: 70px;
  height: 70px;
}

.w-select{
  width:239px;
}
.btn_add{
  position: absolute;
  right:0;
  top:-13px;
}

@media (max-width: 1280px){
  .w-select{
    width:100%;
  }
  .btn_add{
    position: relative;
    display: inline-block;
  }
}

.filtercheck{
  width:20px;
  height: 20px;
}
.filtericon{
  position: relative;
  top:2px;
}

</style>