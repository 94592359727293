<template>

  <div>

    <div class="w-6 h-6 bg-blue-sky bmicon relative" v-if="type == 'bookmark'">
      <svg class="w-6 h-6 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
    </div>

    <div class="w-6 h-6 bg-lemon bmicon relative" v-if="type == 'selection'">
      <svg class="w-6 h-6 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
    </div>
    
    <div class="w-6 h-6 bg-black text-white bmicon relative" v-if="type == 'note'">
      <svg class="w-6 h-6 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
    </div>
    
    <div class="w-6 h-6 icon_attachments bmicon relative" v-if="type == 'link'">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pos-center" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
      </svg>
    </div>
    
    <div class="w-6 h-6 icon_attachments bmicon relative" v-if="type == 'download'">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pos-center" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg>      
    </div>
    
  </div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'

import gsap from 'gsap'

var lv = {}
lv.thisVue = null

export default {
  name: "bookmark_icon",
  props: ['type'],
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style>

.icon_attachments{
  background: #d0d0d0;
  border-radius: 0px 0px 8px 0px;
}

</style>

