<template>
  <div class="">

    <admin @admin_allow='admin_allow' v-if="preview_mode" />

    <div v-if="allowed">


      <div class="" v-if="show === 'yes'">
        <div class="relative">

          <div class="absolute top-0 left-0 w-full h-full overflow-hidden" ref="hdr_img" id="course_img_wrap">
            <div class="pos-g bg-cover bg-center bg-gray-800" v-bind:style="{ 'background-image': 'url(' + img + ')' }" id="course_hdr_img"></div>
            <div class="pos-g bg-black course_opac"></div>
          </div>

          <div class="absolute top-0 left-0 w-full h-full bg-gray-800 overflow-hidden" ref="hdr_img_wrap" id="hdr_move">
            <div class="pos-g" id="course_img_scroll">
              <div class="w-full pos-center">
                <img :src="img" class="w-full" ref="hdr_image" id="course_img" /> 
              </div>
            </div>
            <div class="pos-g bg-black course_opac"></div>
            <div class="pos-g bg-black" id="course_img_curtain"></div>
          </div>

          <div class="relative mode-center" id="course_hdr_wrap">
            <div class="course_hdr_pad">
              <progress_bar progress_text="voortgang" :percentage="percentage" />
              <div class="text-white">
                <div class="text-hdr-4 pt-8 ">
                  {{ title }}
                </div>
                <div class="text-subhdr-1 font-mono pt-2">
                  {{ description }}
                </div>
                <div class="text-subhdr-3 pt-4" v-html="text">
                </div>

                <div class="inline-block align-top pt-7 course_marginright">
                  <div class="inline-block align-top">
                    <div class="course_iconwrap">
                      <!--  
                      <div class="rounded-full w-full h-full bg-gray-100 opacity-10"></div>
                      -->

                      <img class="icon_100" src="@/assets/img/icons_graphic/100x100/icon_lesson-amount.png" />

                    </div>
                  </div>
                  <div class="inline-block align-top pt-9">
                    <div class="text-body-1">
                      aantal lessen
                    </div>
                    <div class="text-mini-1 font-mono">
                      {{lesson_amount}}
                    </div>
                  </div>
                </div>
                <div class="inline-block align-top pt-7">
                  <div class="inline-block align-top">
                    <div class="course_iconwrap">
                      <!--  
                      <div class="rounded-full w-full h-full bg-gray-100 opacity-10"></div>
                      -->

                      <img class="icon_100" src="@/assets/img/icons_graphic/100x100/icon_study-time.png" />

                    </div>
                  </div>
                  <div class="inline-block align-top pt-9">
                    <div class="text-body-1">
                      duur cursus
                    </div>
                    <div class="text-mini-1 font-mono">
                      {{time_string}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

          <div class="relative" v-bind:style="{ backgroundColor: color_course }">
            <div class="relative mode-center course_lessons_wrap">

              <lesson_card  
                v-for="item in items" 
                :key="item.title" 
                :title="item.title" 
                :teaser_text="item.teaser_text" 
                :description="item.description" 
                :text="item.text" 
                :id="item.id" 
                :slug="item.slug" 
                :img="item.img" 
                :to="item.to"
                :percentage="item.percentage"
                :time_string="item.time_string"
              />
              
            </div>
            <div class="vspace_bottom_course_2"></div>
          </div>
      </div>

      <page_404 v-if="show === '404'" />


    </div>



  </div>
</template>


<script>
import gsap from 'gsap'

import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import admin from '@/components/account/admin'

import progress_bar from '@/components/misc/progress_bar.vue'
import lesson_card from '@/components/lesson_card.vue'
import page_404 from '@/components/page_404.vue'

var lv = {}
lv.thisVue = null

lv.last_scroll_y = 0

export default {
  name: "Course",
  components: {
    progress_bar,
    lesson_card,
    page_404,
    admin
  },
  data() {
    return {
      title: null,
      description: null,
      text: null,
      id: null,
      img: null,
      show: "",
      items: [],
      percentage: 0,
      time_string: null,
      lesson_amount: 0,
      preview_mode: false,
      allowed: true
    }
  },
  mounted() {
    lv.thisVue = this
    lv.thisVue.id = 0
    lv.thisVue.items = []
    lv.slug = lv.thisVue.$route.params.cursus

    this.checkPreviewMode()

    lv.last_scroll_y = 0

    this.$nextTick(function () {
      setTimeout(function(){
        parallax_hdr_init()
      },150)
    })

    lv.apimode = 'normal'
    if(this.preview_mode){
      lv.apimode = 'preview'
    }

    lv.dataTask = "course_fetch"
    lv.dataEvent = "data_course_detail"
    api.call({
      task: lv.dataTask,
      slug: lv.slug,
      global: true,
      mode: lv.apimode,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e);
    })

    function dataLoaded(d) {
      if (d.result != "ok") {
        control_main.console_log("----- data load fail -----")
      }
      if (d.result == "ok") {
        //control_main.console_log("----- data load success -----")

        if (d.data.respond == "404") {
          control_main.console_log("dont have that one")
          lv.thisVue.show = "404";
          return;
        }

        lv.thisVue.show = "yes";

        lv.dataTaskString = lv.dataTask + "_" + lv.slug

        lv.thisVue.id = globals.api_data[lv.dataTaskString].item.id
        lv.thisVue.title = globals.api_data[lv.dataTaskString].item.title
        lv.thisVue.description = globals.api_data[lv.dataTaskString].item.description
        lv.thisVue.text = globals.api_data[lv.dataTaskString].item.text
        lv.thisVue.img = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].item.header_image].file
        lv.thisVue.percentage = globals.api_data[lv.dataTaskString].item.percentage
        lv.thisVue.color_course = globals.api_data[lv.dataTaskString].item.color
        if(!lv.thisVue.color_course){
          lv.thisVue.color_course = '#00d45b'
        }
        lv.thisVue.lesson_amount = globals.api_data[lv.dataTaskString].item.lesson_count
        lv.thisVue.time_string = globals.api_data[lv.dataTaskString].item.hours_to_study_total + ' uur'

        lv.transpose = []
        for (let key in globals.api_data[lv.dataTaskString].lessons) {
          lv.item = []
          lv.item.id = globals.api_data[lv.dataTaskString].lessons[key].id
          lv.item.title = globals.api_data[lv.dataTaskString].lessons[key].title
          lv.item.slug = globals.api_data[lv.dataTaskString].lessons[key].slug
          lv.item.teaser_text = globals.api_data[lv.dataTaskString].lessons[key].teaser_text
          lv.item.description = globals.api_data[lv.dataTaskString].lessons[key].description
          lv.item.text = globals.api_data[lv.dataTaskString].lessons[key].text
          lv.item.img = globals.api_data[lv.dataTaskString].resources[globals.api_data[lv.dataTaskString].lessons[key].header_image].file        
          lv.item.to = lv.slug + '/' + lv.item.slug 
          lv.item.percentage = globals.api_data[lv.dataTaskString].lessons[key].percentage
          lv.item.time_string = globals.api_data[lv.dataTaskString].lessons[key].hours_to_study + ' uur'
          
          lv.transpose.push(lv.item)
        }
        lv.thisVue.items = lv.transpose

      }
    }
  },
  methods:{
    admin_allow(){
      if(this.preview_mode){
        if(globals.admin_user){
          this.allowed = true
          setTimeout(function(){
            parallax_hdr_init()
          },150)
        }else{
          this.allowed = false
        }
      }
    },
    checkPreviewMode(){
      if(this.$route.name === 'PreviewCourse'){
        control_main.console_log('_______ Admin Preview Mode _______')
        this.preview_mode = true
      }else{
        this.preview_mode = false
      }
    }    
  },
  destroyed(){
    gsap.ticker.remove(parallax_hdr)
    control_main.console_log('destroyed course')
  },
}

lv.wrapper_height = null
lv.img_height =  null
lv.remaining_height = null


function parallax_hdr(){
  if(!lv.thisVue.$refs.hdr_img){
    return
  }
  lv.window_scroll = window.pageYOffset
  lv.wrapper_height = lv.thisVue.$refs.hdr_img_wrap.getBoundingClientRect().height
  lv.img_height = lv.thisVue.$refs.hdr_image.getBoundingClientRect().height
  if(lv.img_height <= (lv.wrapper_height + 50)){
    gsap.set('#hdr_move', {visibility: 'hidden'})
    return
  }else{
    gsap.set('#hdr_move', {visibility: 'visible'})
  }
  if(lv.window_scroll > lv.wrapper_height + 10){
    return
  }
  if(lv.last_scroll_y == lv.window_scroll){
    return
  }
  lv.last_scroll_y = lv.window_scroll
  lv.remaining_height = ((lv.img_height - lv.wrapper_height) * 0.5)
  lv.scrollpos = ((lv.window_scroll  / lv.wrapper_height) * lv.remaining_height)
  lv.scrolldest = lv.scrollpos
  gsap.set('#course_img_scroll',{y: lv.scrolldest + 'px'})
  control_main.console_log('para relaxing')
}

function parallax_hdr_init(){
  gsap.to('#course_img_curtain',{duration:0.3, opacity:0, ease:'power3.inOut'})
  gsap.ticker.add(parallax_hdr)
  parallax_hdr()
}

</script>

<style lang="scss">




</style>
