<template>

  <div class="grid grid-cols-2 gap-6 mt-10 mxsm:grid-cols-1">
    <div  v-for="(item, index) in obj" :key="index"
          class="bg-grey-med rounded-xl overflow-hidden relative"
          @mouseenter="mouseover(index)"
          @mouseleave="mouseleave(index)"
          :id="'attachment_' + index"
    >
      <div class="a">
        <div class="flex">
          <div class="mr-5">
            <div class="hidden">
              <bookmark_icon type="link" v-if="item.link && !item.file" />
              <bookmark_icon type="download" v-if="!item.link && item.file" />
            </div>
          </div>
          <div class="pt-4">
            <div class="text-body-1">
              {{item.title}}
            </div>
          </div>
          <div class="ml-auto relative p-2">
            <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
            <div class="pos-g cursor-pointer"></div>
          </div>
        </div>
      </div>

      <div class="pt-1 px-5 pb-5 pr-10">
        <div class="text-subhdr-3">
          {{item.text}}
        </div>
      </div>
      
      <a :href="item.link" target="_blank" class="pos-g cursor-pointer" v-if="item.link && !item.file"></a>
      <a :href="item.filepath" target="_blank" class="pos-g cursor-pointer" v-if="!item.link && item.file"></a>
    
    </div>
  </div>

</template>

<script>
import gsap from 'gsap'

import bookmark_icon from '@/components/misc/bookmark_icon.vue'

var lv = {}
lv.thisVue = null

export default {
  components: {
    bookmark_icon
  },
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    mouseover(who){
      lv.targ = '#attachment_' + who
      gsap.to(lv.targ,{duration:0.3, opacity:1,'background-color': '#caf6dc',  ease:'power3.inOut'})
    },
    mouseleave(who){
      lv.targ = '#attachment_' + who
      gsap.to(lv.targ,{duration:0.3, opacity:1,'background-color': '#e7e7e7',  ease:'power3.inOut'})
    }
  }
}

</script>

<style>


</style>
