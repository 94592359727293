<template>

  <div class="mt-4">

    <div v-if="type === 'open'">
      <textarea   rows="10" class="resize-none rounded-md bg-grey-med text-mini-1 padding_lesson_block_3 block_minh w100 focus:outline-none focus:shadow-outline" 
                  v-model="answer_open" maxlength="1000" placeholder="Jouw antwoord..."
      ></textarea>      
    </div>
    
    <div v-if="type === 'multiple'" :id="'answers_' + question_id">

      <div v-for="(item, index) in obj" :key="index" :value="index" class="rounded-lg multiple_padding bg-grey-light mt-3" :class="'quiz_answer_' + index">

        <div class=" multiple_minh flex items-center cursor-pointer"
          @mouseenter="mouseover(index)"
          @mouseleave="mouseleave(index)"
          @click="setAnswer(index)"
          
        >
          <div class="flex items-center">
            <div class="multiple_radio bg-white flex-shrink-0 ml-8 mr-16 mxmd:ml-4 mxmd:mr-8 relative radio" 
            :class="multi_correct ? 'rounded-lg' : 'rounded-full'"
            >

              <div class="pos-center">
                <div class="opacity-0 radiocheck">                
                  <svg v-if="multi_correct" class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <div v-if="!multi_correct" class="rounded-full bg-black w-5 h-5"></div>
                </div>
              </div>

            </div>
            <div class="text-body-1 flex items-center pr-6">
              <div class="radiotext font-radio select-none">
                {{item.answer}}
              </div>
            </div>
          </div>
        </div>

        <div class="text-subhdr-3 explanation hidden opacity-0" :class="'quiz_explanation_' + index" v-if="item.explanation" v-html="item.explanation"></div>

      </div>

    </div>

  </div>

</template>

<script>
import gsap from 'gsap'
import { globals } from '@/modules/globals'

var lv = {}
lv.thisVue = null

export default {
  props: ['q_obj','div_id','question_id','question_index','obj','multi_correct','type'],
  components: {
  },
  data() {
    return {
      answer_open: null,
      color_highlight: '#36ae95',
      color_chosen: '#36ae70',
      color_incorrect: '#ff8a57',
      correct_mandatory: true,
      chosen: null,
      targetParent: null,
      specs: [],
    }
  },
  mounted() {
    this.chosen = []
    this.targetParent = '#answers_' + this.$props.question_id

    var parent_block_item_done = globals.api_data['lesson_fetch_' + this.$props.q_obj.course_data.lesson_slug].blocks[this.$props.q_obj.index_id].done

    this.$nextTick(function () {
      if(parent_block_item_done){
        //console.log('saved data for quiz ' + this.$props.q_obj.name)
        //console.log('saved data for question ' + this.$props.question_index)

        var parent_specs = JSON.parse(globals.api_data['lesson_fetch_' + this.$props.q_obj.course_data.lesson_slug].blocks[this.$props.q_obj.index_id].specs)
        this.specs = parent_specs['question_' + this.$props.question_index]
        //console.log(this.specs)

        if(this.specs.type === 'open'){
          this.answer_open = this.specs.answers
        }
        if(this.specs.type === 'multiple'){
          if(!this.specs.multi_correct){
            this.setAnswer(this.specs.answers)
          }
          if(this.specs.multi_correct){
            lv.nr = 0
            while(lv.nr < this.specs.answers.length){
              this.setAnswer(this.specs.answers[lv.nr])
              lv.nr++
            }
          }
          /*
          */
        }
      }
    })
    
  },
  methods: {
    mouseover(who){
      this.choice_set(who)
    },
    mouseleave(who){
      this.choice_unset(who)
    },
    choice_set(who){
      if(this.chosen.includes(who)){
        return
      }
      lv.targ = this.targetParent + ' .quiz_answer_' + who
      gsap.to(lv.targ,{duration:0.3, opacity:1,'background-color': this.color_highlight,  ease:'power3.inOut'})
      lv.targ = this.targetParent + ' .quiz_answer_' + who + ' .radio'
      gsap.to(lv.targ,{duration:0.3,  scale: 1.1, ease:'power3.inOut'})
      lv.targ = this.targetParent + ' .quiz_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3,  scale: 1, opacity: 1, ease:'power3.inOut'})
    },
    choice_unset(who){
      if(this.chosen.includes(who)){
        return
      }
      this.explanationHide(who)
      
      lv.targ = this.targetParent + ' .quiz_answer_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': '#e7e7e7', ease:'power3.inOut'})
      lv.targ = this.targetParent + ' .quiz_answer_' + who + ' .radio'
      gsap.to(lv.targ,{duration:0.3, scale: 1, ease:'power3.inOut'})
      lv.targ = this.targetParent + ' .quiz_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3, scale: 0, opacity: 0, ease:'power3.inOut'})

    },
    choice_settle(who){
      var chosen_cur = this.$props.obj[who]
      if(this.chosen.includes(who)){
        this.removeChosen(this.chosen, who)
        this.choice_unset(who)
        //this.incorrect_message_fnc()
        return
      }
      /*
      */

      lv.use_color = this.color_incorrect
      if(this.correct_mandatory){
        if(chosen_cur.correct){
          lv.use_color = this.color_chosen
        }
      }
      this.chosen.push(who)
      /*
      this.incorrect_message_fnc()
      */

      lv.targ = this.targetParent + ' .quiz_answer_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': lv.use_color, ease:'power3.inOut'})
      lv.targ = this.targetParent + ' .quiz_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.2,  scale: 1.2, opacity: 1, ease:'power3.inOut'})
      gsap.to(lv.targ,{delay:0.2, duration:0.2,  scale: 1, opacity: 1, ease:'power3.inOut'})

      this.explanationShow(who)
      //gsap.killTweensOf(this.explanationShow)
      //gsap.delayedCall(0.5, this.explanationShow, [who])
      
    },
    choice_unset_all(){
      for (let [key, value] of Object.entries(this.$props.obj)) {
        this.removeChosen(this.chosen, key)
        this.choice_unset(key)
      }
    },
    setAnswer(who){
      if(!this.$props.multi_correct){
        this.choice_unset_all()
      }
      this.choice_set(who)
      this.choice_settle(who)
      var snd = {
        props: this.$props,
        answers: this.chosen
      }
      this.$parent.answersWatch(snd)
    },
    removeChosen(arr, value) {
      var index = arr.indexOf(value)
      if (index > -1) {
        arr.splice(index, 1)
      }
      return arr
    },
    explanationShow(who){
      lv.targ = this.targetParent + ' .quiz_explanation_' + who
      lv.qs = document.querySelectorAll(lv.targ)
      if(lv.qs.length < 1){
        return
      }
      gsap.to(lv.targ,{duration:0.3, opacity:1, display:'block', ease:'power3.inOut'})
    },
    explanationHide(who){
      lv.targ = this.targetParent + ' .quiz_explanation_' + who
      lv.qs = document.querySelectorAll(lv.targ)
      if(lv.qs.length < 1){
        return
      }
      gsap.to(lv.targ,{duration:0.3, opacity:0, display:'none', ease:'power3.inOut'})
    },
    explanationShow____(who){
      lv.targ = this.targetParent + ' .quiz_explanation_' + who
      lv.qs = document.querySelectorAll(lv.targ)
      if(lv.qs.length < 1){
        return
      }
      gsap.set(lv.targ,{display:'block'})
      lv.th = gsap.getProperty(lv.targ, 'height')
      gsap.set(lv.targ,{display:'none', height: 0})
      gsap.to(lv.targ,{duration:0.3, height: lv.th,  ease:'power3.inOut'})
      gsap.to(lv.targ,{delay:0.3, duration:0.3, opacity:1,display:'block', ease:'power3.inOut'})
      gsap.set(lv.targ,{delay: 0.3, height: 'auto'})
    },
    explanationHide____(who){
      lv.targ = this.targetParent + ' .quiz_explanation_' + who
      lv.qs = document.querySelectorAll(lv.targ)
      if(lv.qs.length < 1){
        return
      }
      gsap.to(lv.targ,{duration:0.3, opacity:0, display:'none', height: 0, ease:'power3.inOut'})
      gsap.set(lv.targ,{delay: 0.3, height: 'auto'})
    },
  },
  watch: {
    answer_open: function(val, oldVal) {
      var snd = {
        props: this.$props,
        answers: [val]
      }
      this.$parent.answersWatch(snd)
    }
  }
}

</script>

<style scoped lang="scss">

  .multiple_minh{
    min-height: 135px;
  }
  .multiple_padding{
    padding:10px 10px 10px 10px;
  }
  .multiple_radio{
    width:50px;
    height: 50px;
  }
  .incorrect_message{
    font-weight: normal;
  }
  .explanation{
    padding: 10px 50px 40px 50px;
  }

</style>