<template>
  <div class="">
    <div class="rounded-lg padding_lesson_block bg-white">
      <div class="text-micro-1 font-normal uppercase pb-8 mxsm:pt-16">
        {{blockObj.name}}
      </div>
      <div class="wrap_bookmark">
        <div class="flex">
          <div class="txt_bookmark text-micro-3 font-mono flex-shrink-0">
            sla op als takeaway
          </div>
          <div class="ticon_bookmark rounded-full bg-blue-sky ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out" @click="bookmark">
            <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
          </div>
        </div>
      </div>
      <div class="text-hdr-4 pb-4">
        {{blockObj.title}}
      </div>
      <div class="text-subhdr-3 wysiwyg_text bookmarkable" v-html="blockObj.text" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
      </div>
      <div class="a" v-if="blockObj.foldouts">
        <foldouts :obj="blockObj.foldouts" />
      </div>
      <div class="a" v-if="blockObj.attachments">
        <attachments :obj="blockObj.attachments" />
      </div>
    </div>

    <div class="rounded-lg padding_lesson_block bg-white mt-3">

      <div class="text-hdr-5">
        {{blockObj.block_quiz_title}}
      </div>

      <div  v-for="(item, index) in blockObj.quizitems" :key="index">
        <div class="text-micro-1 font-normal uppercase mb-2 mt-8">
          vraag {{Number(index)+1}}
        </div>
        <div class="text-hdr-5 mb-6">
          {{item.question}}
        </div>
        <quizanswers :obj="item.answers" :q_obj="blockObj" :type="item.type" :multi_correct="item.multi_correct" :div_id="div_id" :question_id="item.id" :question_index="index" />
      </div>
      
    </div>


    <div class="flex items-center mxmd:block mt-12">
      <div class="ml-auto btn_minw mxmd:text-right mxmd:mt-6">  
        <btn_default text="ga verder" type="wide" :state="next_disabled" @btn-click="saveAnswers"  />
      </div>
    </div>
    
  </div>
</template>

<script>
import gsap from 'gsap'
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import btn_default from '@/components/ui/btn_default.vue'
import attachments from '@/components/misc/attachments.vue'
import foldouts from '@/components/misc/foldouts.vue'
import quizanswers from '@/components/misc/quizanswers.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'blockimage',
  components: {
    btn_default,
    attachments,
    foldouts,
    quizanswers
  },
  props: ['div_id','type','blockObj'],
  data() {
    return {
      answer: null,
      next_disabled: 'disabled',
      answered: {},
      answer_specs: {}
   }
  },
  mounted() {
    lv.thisVue = this

    var block_item_done = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done

    this.$nextTick(function () {
      if(block_item_done){
        this.setDone()
      }
    })
  },
  methods: {
    answersWatch(snd){
      console.log('answersWatch')
      console.log(snd)

      console.log(this.$props.blockObj)

      if(snd.props.type == 'multiple'){
        if(!snd.props.multi_correct){
          this.answered['answer_to_question_' + snd.props.question_index] = snd.answers[0]
          this.answered['correct_answers_question_' + snd.props.question_index] = false
          if(this.$props.blockObj.quizitems[snd.props.question_index].answers[snd.answers[0]].correct){
            this.answered['correct_answers_question_' + snd.props.question_index] = true
          }
        }
        if(snd.props.multi_correct){
          this.answered['answer_to_question_' + snd.props.question_index] = snd.answers
          this.answered['correct_answers_question_' + snd.props.question_index] = false

          lv.nr = 0
          lv.correct_count = 0
          lv.correct_answered_count = 0
          lv.src = this.$props.blockObj.quizitems[snd.props.question_index].answers

          lv.allCorrect = true
          for (let [key, value] of Object.entries(lv.src)) {
            if(value.correct){
              lv.correct_count++
              lv.iscorrect = key
              if(snd.answers.includes(lv.iscorrect)){
                lv.correct_answered_count++
              }
            }else{
              lv.allCorrect = false
            }
          }

          if(lv.correct_count == lv.correct_answered_count){
            if(snd.answers.length == lv.correct_count){
              this.answered['correct_answers_question_' + snd.props.question_index] = true
            }
          }

          /*
          console.log('lv.allCorrect', lv.allCorrect)
          console.log('snd', snd)
          console.log('lv.src', lv.src)
          */

          if(lv.allCorrect){
            if(snd.answers.length > 0){
              console.log('at least one answered')
              this.answered['correct_answers_question_' + snd.props.question_index] = true
            }else{
              console.log('none answered')
              this.answered['correct_answers_question_' + snd.props.question_index] = false
            }
          }

        }
      }

      if(snd.props.type == 'open'){
        this.answered['answer_to_question_' + snd.props.question_index] = snd.answers[0]
        this.answered['correct_answers_question_' + snd.props.question_index] = false
        if(snd.answers[0].length > 1){
          this.answered['correct_answers_question_' + snd.props.question_index] = true
        }
      }

      //console.log(this.answered)

      lv.question_count = 0
      lv.question_correct_count = 0
      lv.src = this.$props.blockObj.quizitems
      for (let [keyQ, valueQ] of Object.entries(lv.src)) {
        lv.question_count++
        if(this.answered['correct_answers_question_' + keyQ]){
          lv.question_correct_count++
        }
      }
      

      this.next_disabled = 'disabled'
      if(lv.question_correct_count === lv.question_count){
        //console.log('---------- Ga Verder Toegestaan')
        this.setDone()
      }

    },
    setDone(){
      this.next_disabled = ''
    },
    saveAnswers() {

      this.answer_specs = {}

      lv.question_count = 0
      lv.src = this.$props.blockObj.quizitems
      for (let [keyQ, valueQ] of Object.entries(lv.src)) {
        this.answer_specs['question_' + lv.question_count] = {}
        this.answer_specs['question_' + lv.question_count].type = valueQ.type
        if(valueQ.multi_correct){
          this.answer_specs['question_' + lv.question_count].multi_correct = valueQ.multi_correct
        }
        this.answer_specs['question_' + lv.question_count].correct = this.answered['correct_answers_question_' + keyQ]
        this.answer_specs['question_' + lv.question_count].answers = this.answered['answer_to_question_' + keyQ]
        lv.question_count++
      }

      //console.log(this.answer_specs)

      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done = true
      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].specs = JSON.stringify(this.answer_specs)

      lv.signal = {
        index: this.blockObj.index_id,
        lesson: this.blockObj.course_data.lesson_slug
      }
      EventBus.$emit('lesson_block_done', lv.signal)

      lv.answer_data = {
        type: this.blockObj.type,
        block_id: this.blockObj.id,
        lesson_id: this.blockObj.course_data.lesson_id,
        course_id: this.blockObj.course_data.course_id,
        lesson_slug: this.blockObj.course_data.lesson_slug,
        course_slug: this.blockObj.course_data.course_slug,
        user_id: globals.user_data.id,
        action: 'done',
        answer_specs: this.answer_specs
      }
      api.call({
        task: 'answer_save',
        event: 'data_answer_save',
        answerData: lv.answer_data,
        slug: this.blockObj.course_data.lesson_slug,
        global: false
      })

      this.next()

    },
    next() {
      EventBus.$emit('lesson_nav', this.blockObj.index_id + 1)
    },
    bookmark(){   
      lv.modal_data = {
        type: 'bookmark',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      //EventBus.$emit('bookmark_selection')
    }   
  }
}
</script>

<style scoped lang="scss">


</style>
