<template>
  <div class="">

    <!--  
    <div class="spacer_v_lesson_block"></div>
    -->

    <div class="mode-center">
        <div class="block_hdr_pad">
          <div class="text-body-1 text-white breadcrumb breadcrumb_block">
            <router-link :to="course_url" exact class="inline-block relative hover:underline">{{lesson_endObj.course_data.course_title}}</router-link>
             > {{lesson_endObj.course_data.lesson_title}}
          </div>
        </div>
        <div class="">
          <div class="rounded-t-lg padding_lesson_block pad-less-end bg-white relative">
            <div class="grid grid-cols-12 gap-4 mxmd:grid-cols-6">
              <div class="col-span-10 mxmd:col-span-5 mxsm:col-span-6">
                <div class="text-micro-1 font-normal uppercase pb-3">
                  {{lesson_endObj.type_name}}
                </div>
                <div class="text-subhdr-3 wysiwyg_text" v-html="lesson_endObj.text">
                </div>
              </div>
              <div class="col-span-2 mxmd:col-span-1 mxsm:col-span-2">
                <svg class="w-full h-full iconend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" width="283.4599914550781" height="283.4599914550781"><g class="a"><circle class="b svg-elem-1" cx="141.73" cy="141.73" r="115.63"></circle><rect class="c svg-elem-2" x="80.57" y="78.3" width="122.32" height="122.32"></rect><polygon class="d svg-elem-3" points="126.69 163.59 221.72 81.13 225.86 85.27 126.69 212.12 68.22 125.32 71.99 121.91 126.69 163.59"></polygon></g></svg>
              </div>
            </div>
            <div class="v-space-lesson-end"></div>
            <div class="text-hdr-1 " v-if="items_result.length > 0">
              Jouw antwoorden deze les
            </div>
          </div>

          <div v-for="(item, index) in items_result" :key="index"
           class="bg-white pad-less-end-answer mb-less-end">
            <div class="text-subhdr-1 font-mono">
              {{item.nr}}
            </div>
            <div class="text-subhdr-3 font-boldy">
              {{item.title}}
            </div>
            <div class="text-subhdr-3">
              {{item.text}}
            </div>
          </div>

          <div class="rounded-b-lg p-5 bg-white"></div>
        </div>

        <div class="flex items-center mxmd:block mt-12">
          <div>
            <router-link  :to="'/cursussen/' + lesson_endObj.course_data.course_slug" class="text-subhdr-3 block lesson_btn mt-1 rounded-md px-2 pt-1 mxmd:pt-2 pb-2 hover:bg-black hover:text-white focus:outline-none transition duration-200 ease-in-out" >
              <div class="inline-block align-middle mr-2">   
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>                
              </div>
              <div class="inline-block align-middle font-bold">                   
                terug naar overzicht
              </div>
            </router-link>
          </div>
          <div class="ml-auto btn_minw mxmd:text-right mxmd:mt-6">  
            <btn_default text="volgende les" :to="link_next_lesson" type="wide" @btn-click="next_end" v-show="next_lesson_visible"  />
          </div>
        </div>
        <div class="vspace_lesson_block_bottom"></div>
    </div>

  </div>




</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null

export default {
  name: "lesson_end",
  components: {
    btn_default
  },
  props: ['lesson_endObj'],
  data() {
    return {
      items_result:[],
      next_lesson_visible: true,
      link_next_lesson: '/',
      course_url: '/cursussen/'
    }
  },
  mounted() {
    lv.thisVue = this

    this.course_url = '/cursussen/' + this.lesson_endObj.course_data.course_slug
    if(this.lesson_endObj.preview_mode){
      this.course_url = '/preview/cursussen/' + this.lesson_endObj.course_data.course_slug
    }

    //this.results()

    EventBus.$on('lesson_finished', (e) => {
      this.results()
    })

    
  },
  methods: {
    next_end(){
      //control_main.console_log('next_end')
    },
    results(){

      lv.slug = lv.thisVue.$route.params.lesson
      lv.types_answer = ['question','multiplechoice','rangeslider']
      lv.items_result = []
      lv.cnt = 0
      lv.lesson_blocks = globals.api_data['lesson_fetch_' + lv.slug].blocks
      for (let key in lv.lesson_blocks ) {
        if(lv.types_answer.includes(lv.lesson_blocks[key].type)){
          lv.cnt++
          lv.answer = ''
          lv.specs = JSON.parse(lv.lesson_blocks[key].specs)
          if(lv.lesson_blocks[key].type === 'question'){
            lv.answer = lv.specs.answer
          }
          if(lv.lesson_blocks[key].type === 'rangeslider'){
            lv.answer = lv.specs.label
          }
          if(lv.lesson_blocks[key].type === 'multiplechoice'){
            lv.answer = ''
            lv.nr = 0
            while(lv.nr < lv.specs.answers.length){
              lv.answer += lv.specs.answers[lv.nr].label
              lv.nr++
              if(lv.nr < lv.specs.answers.length){
                lv.answer += ', '
              }
            }
          }
          lv.item_cur = {
            nr: lv.cnt,
            title: lv.lesson_blocks[key].title,
            text: lv.answer
          }
          lv.items_result.push(lv.item_cur)
        }
      }
      this.items_result = lv.items_result

      lv.cur_lesson_nr = 1
      control_main.console_log('total lesson number: ' + this.lesson_endObj.other_lessons_data['total'])
      for (const [key, value] of Object.entries(this.lesson_endObj.other_lessons_data['slugs'])) {
        control_main.console_log(key, value)
        if(key === lv.slug){
          control_main.console_log('current lesson number: ' + value)
          if(value == this.lesson_endObj.other_lessons_data['total']){
            this.next_lesson_visible = false
          }else{
            lv.cur_lesson_nr = value
          }
        }
      }
      if(this.next_lesson_visible){
        for (const [key, value] of Object.entries(this.lesson_endObj.other_lessons_data['slugs'])) {
          if(value == lv.cur_lesson_nr + 1){
            control_main.console_log('next lesson slug = ' + key)
            this.link_next_lesson = '/cursussen/' + this.lesson_endObj.course_data.course_slug + '/' + key
          }
        }
      }else{
        control_main.console_log('last lesson in this course')
      }

    }
  }
}


</script>

<style scoped lang="scss">

.v-space-lesson-end{
    height: 90px;
}
.pad-less-end{
  padding-bottom: 10px;
}
.pad-less-end-answer{
  padding: 10px 78px 20px 78px;
}
.font-boldy{
  font-weight: 700;
}
.mb-less-end{
  margin-bottom:3px;
}
.icon_wrap_lesson_end{
  position: absolute;
  right: 70px;
  top: 35px;
  width:140px;
  height:140px;
}

.iconend{
  position: relative;
  top: -25px;
  right: -50px;
}

@media (max-width: 720px){
  .iconend{
    top: -10px;
    right: -20px;
  }
}
@media (max-width: 640px){
  .iconend{
    top: 0px;
    right: 10px;
  }
  .v-space-lesson-end{
      height: 50px;
  }
}


.a{isolation:isolate;}.b{fill:#dfeaf8;mix-blend-mode:multiply;}.b,.c{stroke:#1d1d1b;stroke-linejoin:bevel;stroke-width:2px;}.c{fill:#fff;}.d{fill:#1d1d1b;}



@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 728.5247170691755px;
    stroke-dasharray: 728.5247170691755px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(223, 234, 248);
  }
}

.svg-elem-1 {
  animation: animate-svg-stroke-1 0.8s ease-in-out 0.2s both,
               animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 491.27996826171875px;
    stroke-dasharray: 491.27996826171875px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-2 {
  animation: animate-svg-stroke-2 0.8s ease-in-out 0.4s both,
               animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 473.19775390625px;
    stroke-dasharray: 473.19775390625px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(29, 29, 27);
  }
}

.svg-elem-3 {
  animation: animate-svg-stroke-3 0.8s ease-in-out 0.6000000000000001s both,
               animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}


</style>
