<template>
  <div class="preview">

    <div v-if="allowed">

      <div class="vspace_top_page"></div>
      <div class="mode-center">

        <div class="">
          <div class="">
            <div class="text-hdr-1">
              Hey {{admin_fullname}}
            </div>
            <div class="text-subhdr-1 font-mono mt-3 pr-3">
              Welkom bij de preview
            </div>
            <div class="mt-8">
              <router-link to="/preview/cursussen" class="rounded py-1 px-3 bg-gray-300 hover:bg-black hover:text-white">Preview cursussen</router-link>
            </div>
          </div>
        </div>

        <div class="vspace_middle_page_4"></div>
      </div>

    </div>

    <admin @admin_allow='admin_allow' />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import * as cheet from 'cheet.js'

import admin from '@/components/account/admin'

var lv = {}
lv.thisVue = null

export default {
  name: 'Preview',
  components: {
    admin
  },
  data() {
    return {
      allowed: false,
      admin_fullname: null
    }
  },
  mounted() {

    lv.thisVue = this

    this.admin_allow()

  },
  methods:{
    admin_allow(){
      if(globals.admin_user){
        this.allowed = true
        this.admin_fullname = globals.admin_user.first_name + ' ' + globals.admin_user.last_name
      }else{
        this.allowed = false
      }
    }
  }
}
</script>

<style scoped>


</style>