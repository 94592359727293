<template>
  <div class="">
    <div class="rounded-lg padding_lesson_block bg-white">
      <div class="text-micro-1 font-normal uppercase pb-8 mxsm:pt-16">
        {{blockObj.name}}
      </div>
      <div class="wrap_bookmark">
        <div class="flex">
          <div class="txt_bookmark text-micro-3 font-mono flex-shrink-0">
            sla op als takeaway
          </div>
          <div class="ticon_bookmark rounded-full bg-blue-sky ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out" @click="bookmark">
            <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
          </div>
        </div>
      </div>
      <div class="text-hdr-4 pb-4">
        {{blockObj.title}}
      </div>
      <div class="text-subhdr-3 wysiwyg_text bookmarkable" v-html="blockObj.text" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
      </div>
      <div class="a" v-if="blockObj.foldouts">
        <foldouts :obj="blockObj.foldouts" />
      </div>
      <div class="a" v-if="blockObj.attachments">
        <attachments :obj="blockObj.attachments" />
      </div>
    </div>
    <div class="rounded-lg bg-grey-light mt-3 overflow-hidden relative">
      <img :src="blockObj.file" class="blockimage" />
      <div class="pos-g"></div>
    </div>
    <div class="padding_caption text-micro-2 italic" v-html="blockObj.block_image_caption">
    </div>
    <div class="flex items-center mxmd:block">
      <div class="flex items-center relative mxmd:w-full">
        <div class="rounded bg-white block_check mr-5">
          <div class="opacity-0 radiocheck block_check relative">                
            <svg class="w-10 h-10 pos-center" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          </div>
        </div>
        <div class="text-body-1">
          Ik heb dit gezien
        </div>
        <div class="pos-g cursor-pointer" @click="saveAnswer()"></div>
      </div>
      <div class="ml-auto btn_minw mxmd:text-right mxmd:mt-6">  
        <btn_default text="ga verder" type="wide" :state="next_disabled" @btn-click="next"  />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import btn_default from '@/components/ui/btn_default.vue'
import attachments from '@/components/misc/attachments.vue'
import foldouts from '@/components/misc/foldouts.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'blockimage',
  components: {
    btn_default,
    attachments,
    foldouts
  },
  props: ['div_id','type','blockObj'],
  data() {
    return {
      answer: null,
      next_disabled: 'disabled'
   }
  },
  mounted() {
    lv.thisVue = this

    var block_item_done = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done

    this.$nextTick(function () {
      lv.targ = '#' + this.div_id + ' .radiocheck'
      gsap.set(lv.targ,{scale:0})
      if(block_item_done){
        this.setDone()
      }
    })
  },
  methods: {
    setDone(){
      this.next_disabled = ''
      lv.targ = '#' + this.div_id + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3,  scale: 1, opacity: 1, ease:'power3.inOut'})
    },
    saveAnswer() {

      this.setDone()

      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done = true

      lv.signal = {
        index: this.blockObj.index_id,
        lesson: this.blockObj.course_data.lesson_slug
      }
      EventBus.$emit('lesson_block_done', lv.signal)

      lv.answer_data = {
        type: this.blockObj.type,
        block_id: this.blockObj.id,
        lesson_id: this.blockObj.course_data.lesson_id,
        course_id: this.blockObj.course_data.course_id,
        lesson_slug: this.blockObj.course_data.lesson_slug,
        course_slug: this.blockObj.course_data.course_slug,
        user_id: globals.user_data.id,
        action: 'done',
        answer_specs: {}
      }
      api.call({
        task: 'answer_save',
        event: 'data_answer_save',
        answerData: lv.answer_data,
        slug: this.blockObj.course_data.lesson_slug,
        global: false
      })

    },
    next() {
      EventBus.$emit('lesson_nav', this.blockObj.index_id + 1)
    },
    bookmark(){   
      lv.modal_data = {
        type: 'bookmark',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      //EventBus.$emit('bookmark_selection')
    }   
  }
}
</script>

<style scoped lang="scss">


</style>
