<template>
  <div class="">
    <div class="rounded-lg padding_lesson_block bg-white">
      <div class="text-micro-1 font-normal uppercase pb-8 mxsm:pt-16">
        {{blockObj.name}}
      </div>
      <div class="wrap_bookmark">
        <div class="flex">
          <div class="txt_bookmark text-micro-3 font-mono flex-shrink-0">
            sla op als takeaway
          </div>
          <div class="ticon_bookmark rounded-full bg-blue-sky ticon flex-shrink-0 cursor-pointer hover:bg-black hover:text-white transition duration-300 ease-in-out" @click="bookmark">
            <svg class="w-10 h-10 pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
          </div>
        </div>
      </div>
      <div class="text-hdr-4 pb-4">
        {{blockObj.title}}
      </div>
      <div class="text-subhdr-3 wysiwyg_text bookmarkable" v-html="blockObj.text" v-on:mouseup="bookmark_selection" v-on:touchend="bookmark_selection">
      </div>
      <div class="a" v-if="blockObj.foldouts">
        <foldouts :obj="blockObj.foldouts" />
      </div>
      <div class="a" v-if="blockObj.attachments">
        <attachments :obj="blockObj.attachments" />
      </div>
    </div>

    <div class="rounded-lg bg-grey-light mt-3 overflow-hidden relative" v-if="featured_video">
      <div class="video_wrap">
        <vue-plyr ref="plyr_youtube" v-if="video_youtube">
          <div class="plyr__video-embed">
            <iframe allowfullscreen allowtransparency allow="autoplay" :src="src_youtube"></iframe>
          </div>
        </vue-plyr>
        <vue-plyr ref="plyr_vimeo" v-if="video_vimeo">
          <div class="plyr__video-embed">
            <iframe allowfullscreen allowtransparency allow="autoplay" :src="src_vimeo"></iframe>
          </div>
        </vue-plyr>
      </div>
    </div>

    <div>
      <div class="rounded-lg multiple_padding bg-grey-light mt-3 multiple_minh flex items-center cursor-pointer"
        v-for="item in items"
        :key="item.key"
        :value="item.key"
        @mouseenter="mouseover(item.key)"
        @mouseleave="mouseleave(item.key)"
        @click="setAnswer(item.key)"
        :class="'mc_answer_' + item.key"
      >
        <div class="flex items-center">
          <div class="multiple_radio bg-white flex-shrink-0 ml-8 mr-16 mxmd:ml-4 mxmd:mr-8 relative radio" 
           :class="multi ? 'rounded-lg' : 'rounded-full'"
           >

            <div class="pos-center">
              <div class="opacity-0 radiocheck">                
                <svg v-if="multi === true" class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                <div v-if="multi === false" class="rounded-full bg-black w-5 h-5"></div>
              </div>
            </div>

          </div>
          <div class="text-body-1 flex items-center pr-6">
            <div class="radiotext font-radio select-none">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="flex items-center mxmd:block mt-12">
      <div class="text-body-1 text-orange incorrect_message pr-3 opacity-0" ref="incorrect_message">
        Dit is niet het juiste antwoord. Welke wel?
      </div>
      <div class="ml-auto btn_minw mxmd:text-right mxmd:mt-6">  
        <btn_default text="ga verder" type="wide" :state="next_disabled" @btn-click="saveAnswer"  />
      </div>
    </div>
    
  </div>
</template>

<script>
import gsap from 'gsap'
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import btn_default from '@/components/ui/btn_default.vue'
import attachments from '@/components/misc/attachments.vue'
import foldouts from '@/components/misc/foldouts.vue'



var lv = {}
lv.thisVue = null

export default {
  name: 'multiplechoice',
  components: {
    btn_default,
    attachments,
    foldouts
  },
  props: ['div_id','type','blockObj'],
  data() {
    return {
      answer: null,
      answers: null,
      answer_count: 0,
      correct_count: 0,
      multi: true,
      items:[],
      chosen: null,
      specs: [],
      next_disabled: 'disabled',
      color_highlight: '#36ae95',
      color_chosen: '#36ae70',
      color_incorrect: '#ff8a57',
      correct_mandatory: true,
      nr: 0,
      video_youtube: false,
      video_vimeo: false,
      src_youtube: null,
      src_vimeo: null,
      featured_video: false
    }
  },
  mounted() {
    lv.thisVue = this

    this.chosen = []

    this.answer_count = 0
    this.answers = JSON.parse(this.$props.blockObj.block_multiplechoice_answers)
    this.answers.forEach(this.parseAnswers)

    var multi = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].block_multiplechoice_more_answers

    if(multi == '1'){
      this.multi = true
    }else{
      this.multi = false
    }

    var block_item_done = globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done
    
    this.$nextTick(function () {
      lv.targ = '#' + this.$props.div_id + ' .radiocheck'
      gsap.set(lv.targ,{scale:0})
      if(block_item_done){
        this.specs = JSON.parse(globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].specs)
        if(this.specs.answer_numbers){
          this.nr = 0
          while(this.nr < this.specs.answer_numbers.length){
            this.setAnswer(this.specs.answer_numbers[this.nr])
            this.nr++
          }
        }
      }
    })

    lv.host_string = window.location.protocol + "//" + window.location.host
    lv.youtube_embed_vars = "?origin=" + lv.host_string + "&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"

    if(this.$props.blockObj.block_multiplechoice_youtube){
      //this.src_youtube = "https://www.youtube.com/embed/" + this.$props.blockObj.block_multiplechoice_youtube + "?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
      this.src_youtube = "https://www.youtube.com/embed/" + this.$props.blockObj.block_multiplechoice_youtube + lv.youtube_embed_vars
      this.video_youtube = true
      this.featured_video = true
    } else if(this.$props.blockObj.block_multiplechoice_vimeo){
      this.src_vimeo = "https://player.vimeo.com/video/" + this.$props.blockObj.block_multiplechoice_vimeo + "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
      this.video_vimeo = true
      this.featured_video = true
    }

    EventBus.$on('lesson_part_reveal', (e) => {
      if(this.src_youtube && this.$refs.plyr_youtube){
        this.$refs.plyr_youtube.player.pause()
        control_main.console_log('pause video youtube')
      }
      if(this.src_vimeo && this.$refs.plyr_vimeo){
        this.$refs.plyr_vimeo.player.pause()
        control_main.console_log('pause video vimeo')
      }
    })

  },
  methods: {
    parseAnswers(value) {
      this.answer_count++
      lv.item_specs = {
        key: this.answer_count,
        label: value['Answer']
      }
      if(value['Correct']){
        lv.item_specs['correct'] = true
        this.correct_count++
      }else{
        lv.item_specs['correct'] = false
      }
      this.items.push(lv.item_specs)
    },
    mouseover(who){
      this.choice_set(who)
    },
    mouseleave(who){
      this.choice_unset(who)
    },
    choice_set(who){
      if(this.chosen.includes(who)){
        return
      }
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who
      gsap.to(lv.targ,{duration:0.3, opacity:1,'background-color': this.color_highlight,  ease:'power3.inOut'})
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who + ' .radio'
      gsap.to(lv.targ,{duration:0.3,  scale: 1.1, ease:'power3.inOut'})
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3,  scale: 1, opacity: 1, ease:'power3.inOut'})
    },
    choice_unset(who){
      if(this.chosen.includes(who)){
        return
      }
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': '#e7e7e7', ease:'power3.inOut'})
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who + ' .radio'
      gsap.to(lv.targ,{duration:0.3, scale: 1, ease:'power3.inOut'})
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.3, scale: 0, opacity: 0, ease:'power3.inOut'})
    },
    choice_settle(who){
      if(this.chosen.includes(who)){
        this.removeChosen(this.chosen, who)
        this.choice_unset(who)
        this.incorrect_message_fnc()
        return
      }

      lv.use_color = this.color_incorrect
      if(this.correct_mandatory){
        lv.answer_info = this.answers[who - 1]
        if(lv.answer_info['Correct']){
          lv.use_color = this.color_chosen
        }
      }
      this.chosen.push(who)
      this.incorrect_message_fnc()

      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who
      gsap.to(lv.targ,{duration:0.3, 'background-color': lv.use_color, ease:'power3.inOut'})
      lv.targ = '#' + this.$props.div_id + ' .mc_answer_' + who + ' .radiocheck'
      gsap.to(lv.targ,{duration:0.2,  scale: 1.2, opacity: 1, ease:'power3.inOut'})
      gsap.to(lv.targ,{delay:0.2, duration:0.2,  scale: 1, opacity: 1, ease:'power3.inOut'})
    },
    incorrect_message_fnc(){
      //control_main.console_log(this.answers)
      //control_main.console_log(this.chosen)
      lv.incorrect_message_show = false
      lv.nr = 0
      while(lv.nr < this.chosen.length){
        if(!this.answers[this.chosen[lv.nr]-1]['Correct']){
          lv.incorrect_message_show = true
        }
        lv.nr++
      }
      if(lv.incorrect_message_show){
        lv.targ = this.$refs.incorrect_message
        gsap.to(lv.targ,{duration:0.3, opacity: 1, ease:'power3.inOut'})
        this.next_disabled = 'disabled'
      }else{
        lv.targ = this.$refs.incorrect_message
        gsap.to(lv.targ,{duration:0.3, opacity: 0, ease:'power3.inOut'})
        if(lv.nr == this.correct_count){
          this.next_disabled = ''
        }else{
          this.next_disabled = 'disabled'
        }
      }
    },
    choice_unset_all(){
      lv.nr = 0
      while(lv.nr < this.answer_count){
        lv.nr++
        this.removeChosen(this.chosen, lv.nr)
        this.choice_unset(lv.nr)
      }
    },
    setAnswer(who){
      if(this.multi != '1'){
        this.choice_unset_all()
      }
      this.choice_set(who)
      this.choice_settle(who)
    },
    saveAnswer() {

      this.answer_specs = {}
      this.answer_specs['type'] = 'single'
      if(this.multi){
        this.answer_specs['type'] = 'multi'       
      }
      this.answer_specs['correct'] = 'no'
      this.answer_specs['answer_numbers'] = this.chosen
      lv.correct_count = 0
      this.answers_info = []
      lv.nr = 0
      while(lv.nr < this.chosen.length){
        this.answers_text = {}
        this.answers_text['label'] = this.items[this.chosen[lv.nr] - 1].label
        if(this.items[this.chosen[lv.nr] - 1].correct){
          this.answers_text['correct'] = 'yes'
          if(!this.multi){
            this.answer_specs['correct'] = 'yes'       
          }
          lv.correct_count++
        }else{
          this.answers_text['correct'] = 'no'
        }
        this.answers_info.push(this.answers_text)
        lv.nr++
      }
      if(this.multi){
        if(lv.correct_count == this.correct_count){
          this.answer_specs['correct'] = 'yes' 
        }
      }
      this.answer_specs['answers'] = this.answers_info

      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].done = true
      globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].blocks[this.blockObj.index_id].specs = JSON.stringify(this.answer_specs)
      
      lv.signal = {
        index: this.blockObj.index_id,
        lesson: this.blockObj.course_data.lesson_slug
      }
      EventBus.$emit('lesson_block_done', lv.signal)

      lv.answer_data = {
        type: this.blockObj.type,
        block_id: this.blockObj.id,
        lesson_id: this.blockObj.course_data.lesson_id,
        course_id: this.blockObj.course_data.course_id,
        lesson_slug: this.blockObj.course_data.lesson_slug,
        course_slug: this.blockObj.course_data.course_slug,
        user_id: globals.user_data.id,
        action: 'done',
        answer_specs: this.answer_specs
      }
      api.call({
        task: 'answer_save',
        event: 'data_answer_save',
        answerData: lv.answer_data,
        slug: this.blockObj.course_data.lesson_slug,
        global: false
      })

      this.next()
      
    },
    next() {
      EventBus.$emit('lesson_nav', this.blockObj.index_id + 1)
    },
    removeChosen(arr, value) {
      var index = arr.indexOf(value)
      if (index > -1) {
        arr.splice(index, 1)
      }
      return arr
    },
    bookmark(){   
      lv.modal_data = {
        type: 'bookmark',
        meta: globals.api_data['lesson_fetch_' + this.blockObj.course_data.lesson_slug].item,
        block: this.blockObj
      }
      EventBus.$emit('modal_lesson_open', lv.modal_data)      
    },
    bookmark_selection(e){
      //EventBus.$emit('bookmark_selection')
    }   
  }
}

</script>

<style scoped lang="scss">

  .multiple_minh{
    min-height: 135px;
  }
  .multiple_padding{
    padding:10px 10px 10px 10px;
  }
  .multiple_radio{
    width:50px;
    height: 50px;
  }
  .incorrect_message{
    font-weight: normal;
  }

</style>
