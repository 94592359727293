<template>
  <div class="wrap_404">
    <div class="vspace_top_page"></div>
    <div class="bg-white border text-orange-600 p-6 rounded-lg border-gray-400">
      <div class="inline-block align-middle pr-3 text-orange-300">
        <svg class="w-32 h-32" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
      </div>
      <div class="inline-block align-middle text-subhdr-3 pb-3">
        <p class="text-hdr-2">
          <span id="title_404">Oeps</span>
          <span id="text_404">!</span>
          </p>
        <p>Sorry, deze pagina bestaat niet (meer?)</p>
      </div>
    </div>   
    <div class="vspace_bottom"></div>
  </div>
</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

var lv = {}
lv.thisVue = null

export default {
  name: "page_404",
  props: [],
  data() {
    return {
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
  .wrap_404 {
    padding: 0px 20px 20px 20px;
    max-width: 1080px;
    margin: 0 auto;
  }
</style>
