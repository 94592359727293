<template>

  <div class="mt-10">
    <div v-for="(item, index) in obj" :key="index">      
      <foldout :obj="item" />
    </div>
  </div>

</template>

<script>
import gsap from 'gsap'

import foldout from '@/components/misc/foldout.vue'

var lv = {}
lv.thisVue = null

export default {
  props: ['obj'],
  components: {
    foldout
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}

</script>

