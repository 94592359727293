<template>

  <div class="fixed sidebar_wrap hidden" v-bind:style="{ backgroundColor: sidebar.color }" id="sidebar_lesson">
    <div class="sidebar_nav_wrap sidebar_scroll pos-g" id="sidebar_content">
      <div class="sidebar_pad">
        <div class="relative hover:text-white hover:bg-black rounded-md transition duration-200 ease-in-out inline-block ml-2">
          <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          <div class="pos-g cursor-pointer" @click.prevent="$emit('sidebar-x')"></div>
        </div>
        <div class="h-6"></div>
        <div class="text-subhdr-3 ml-2">
          <div class="relative"
            v-for="(block, index) in sidebar.nav"
            :key="index + 1" 
            :id="index + 1"
            :class="sidebar_active_item == (index + 1)  ? 'sidebar_active' : ''"
          >
            <div class="flex font-bold  hover:text-white hover:bg-black p-2 rounded-md transition duration-200 ease-in-out">
              <div class="w-12 flex-shrink-0">
                <span class="px-2 py-1 rounded-md sidebar_nr">
                  {{block.index + 1}}
                </span>
              </div>
              <div class="">
                {{block.name}}
              </div>
              <div class="pos-g cursor-pointer" @click="$emit('lesson-nav', block.index)"></div>
            </div>
            <div class="pos-g opacity-50" v-bind:style="{ backgroundColor: sidebar.color }" v-show="block.usable === false" ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import gsap from 'gsap'

import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

var lv = {}
lv.thisVue = null


export default {
  name: 'sidebar_lesson',
  props: ['sidebar','sidebar_active_item','sidebar_active'],
  data() {
    return {
      sidebar_data: {}
    }
  },
  mounted() {
    lv.thisVue = this

    this.sidebar_data = this.$props.sidebar['nav']

    lv.thisVue.$nextTick(function () {
      stick_init()
    })


},
  methods: {
    mouseOver(){
    },
    mouseOut(){
    }
  },
  destroyed(){
    gsap.ticker.remove(stick)
  },
}

lv.stick_set = 0
lv.stick_point = 117
lv.stick_last = 0
lv.rest_last = 0

function stick(){

  lv.footer_rect = document.getElementById('footer_main').getBoundingClientRect()
  lv.window_height = window.innerHeight
  lv.rest = window.innerHeight - (window.innerHeight - lv.footer_rect.top)
  if(lv.rest != lv.rest_last){
    gsap.set('#sidebar_content',{height: lv.rest + 'px'})
  }
  lv.rest_last = lv.rest

  if(!lv.thisVue.$props.sidebar_active){
    return
  }
  lv.stick_set = lv.stick_point
  lv.window_scroll = window.pageYOffset
  if(lv.window_scroll < lv.stick_point){
    lv.stick_set = lv.window_scroll
  }
  if(lv.stick_set == lv.stick_last){
    return
  }

  gsap.set('#sidebar_lesson',{y: -(lv.stick_set) + 'px'})
  lv.stick_last = lv.stick_set
}

function stick_init(){
  gsap.ticker.add(stick)
  stick()
}



</script>

<style lang="scss" scoped>

.sidebar_wrap{
  left:0;
  top:0;
  width: 100%;
  max-width: 380px;
  border-right:1px solid #000;
  /*
  min-height: 100vh;
  max-height: calc(100vh - 117px);
  */
  height: calc(100vh + 117px);
  box-shadow: 1px 1px 7px rgba(0,0,0,0.3);
}
.sidebar_nav_wrap{
  position: relative;
  top:117px;
  //height:calc(100vh - (117px - 200px));
  height:100vh;
  overflow-y: auto;
}
.sidebar_pad{
  padding: 2rem 1rem 2rem 2rem;
}

.sidebar_scroll {
  --scrollbarBG: rgba(0,0,0,0.1);
  --thumbBG: rgba(0,0,0,0.5);
}
.sidebar_scroll::-webkit-scrollbar {
  width: 9px;
}
.sidebar_scroll {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.sidebar_scroll::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.sidebar_scroll::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.sidebar_active .sidebar_nr{
  color:#fff;
  background: #0000004d;
}


@media (max-width: 520px){
  .sidebar_wrap{
    max-width: 280px;
  }
  .sidebar_pad{
    padding: 2rem 1rem 1rem 1rem;
  }

}




</style>
