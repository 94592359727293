<template>

<div class="rounded-lg bg-blue-ice relative overflow-hidden" :class="['card_' + obj.type]">
  <div class="relative">
    <div class="b">
      <div class="flex">
        <div class="mr-5">
          <bookmark_icon :type="obj.type" />
        </div>
        <div class="pt-2">
          <div class="text-micro-4 uppercase">
            toegevoegd op
          </div>
          <div class="text-body-1">
            {{obj.takeaway_data.date}}
          </div>
        </div>
        <div class="ml-auto relative p-2">
          <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
          <div class="pos-g cursor-pointer" @click="$emit('btn-click')"></div>
        </div>
      </div>
    </div>
    <div class="pt-1 px-5 pb-5">
      <div class="a" v-if="obj.type !== 'note'">
        <div class="pb-2">
          <div class="text-micro-4 uppercase">
            cursus
          </div>
          <div class="text-body-1">
            {{obj.takeaway_data.name_course}}
          </div>
        </div>
        <div class="pb-2">
          <div class="text-micro-4 uppercase">
            les
          </div>
          <div class="text-body-1">
            {{obj.takeaway_data.name_lesson}}
          </div>
        </div>
        <div class="pb-5">
          <div class="text-micro-4 uppercase">
            onderdeel
          </div>
          <div class="text-body-1">
            {{obj.takeaway_data.name_block}}
          </div>
        </div>
      </div>
      <div class="text-subhdr-3"  :class="[obj.type == 'note' ? 'maxh_tw_2' : 'maxh_tw_1']">
        {{obj.takeaway_data.note}}
      </div>
    </div>
  </div>
  <div class="pos-g cursor-pointer" @click="$emit('btn-click')"></div>
</div>

</template>

<script>
import bookmark_icon from '@/components/misc/bookmark_icon.vue'

export default {
  name: "takeaway_card",
  components: {
    bookmark_icon
  },
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
